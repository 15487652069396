import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Customer_header from './Customer_header';
import axios from "axios";
import Swal from 'sweetalert2';


import ReactLoading from 'react-loading';
import config from "../config"
import Header_nav from './Header_nav';
import Footer from './Footer';
const Checkout = () => {
  let navigate = useNavigate()
  let userDetail = localStorage.getItem('ship_rocket_user');

  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const [paid, setPaid] = React.useState({ onpaid: false, order: "" });
  const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })
  const [stateData, setStateData] = React.useState({ codBalance: 0 });
  const [statePaymentMode, setStatePaymentMode] = React.useState({ type: null });
  const [error, setError] = React.useState(null);
  const location = useLocation()
  // console.log("location", location)

  const paypalRef = React.useRef()
  React.useEffect(() => {
    paypalDesign()
    dimension_detail()
    get_wallet_balance();
  }, [])

  const submit_detail = () => {
    let dataToSend = {
      customer_id: userData.customer_id,
      wallet_balance: location.state,
      payment_status: paid.order
    };

    // console.log("datatoSend", dataToSend)
    let url = config.apiUrl + '/user/add_wallet_balance';
    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJson => ", res);
        if (res.data.status == true) {
          navigate('/my_wallet')
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background: "rgb(231, 91, 75)",
            type: 'Success',
            title: "Wallet Balance Added",
            color: "white"
          });

        }
        else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background: "rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Wallet Balance Not Added",
            color: "white"
          });
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        Toast.fire({
          background: "rgb(231, 91, 75)",
          type: 'Unsuccess',
          title: "Something Went Wrong",
          color: "white"
        });
        // console.log(error);
      });

  }

  const codWalletPaySubmit = () => {
    // console.log("submit");
    let url = config.apiUrl + '/user/add_wallet_cod_mode';
    let dataToSend = { addAmount: location.state, customer_id: userData.customer_id };
    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJson => ", res);
        if (res.data.status == true) {
          navigate('/my_wallet')
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background: "#28a745",
            type: 'Success',
            title: "Wallet Balance Added",
            color: "white"
          });

        }
        else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background: "rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Wallet Balance Not Added",
            color: "white"
          });
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        Toast.fire({
          background: "rgb(231, 91, 75)",
          type: 'Unsuccess',
          title: "Something Went Wrong",
          color: "white"
        });
        // console.log(error);
      });
  }

  const paypalDesign = (pp) => {

    if (window.myButton) window.myButton.close();

    window.myButton = window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          intent: "CAPTURE",
          purchase_units: [
            {
              description: "",
              amount: {
                currency_code: "USD",
                value: location.state
              },
            },
          ],
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        setPaid({ ...paid, onpaid: true, order: order });
        // console.log("onpaidstate", paid);

      },
      onError: (err) => {
        //   setError(err),
        // console.error(err);
      },
    })

    window.myButton.render(paypalRef.current);
  }

  const get_wallet_balance = () => {
    let dataToSend = { customer_id: userData.customer_id };

    // console.log("datatoSend", dataToSend)
    let url = config.apiUrl + '/user/get_wallet_balance';
    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJsonCheckout => ", res.data.find_customer_wallet[0]);
        if (res.data.status == true) {
          setStateData({ ...stateData, codBalance: res.data.find_customer_wallet[0].cod_wallet_balance })
        }
        else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background: "rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color: "white"
          });
        }
      })

      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        Toast.fire({
          background: "rgb(231, 91, 75)",
          type: 'Unsuccess',
          title: "Something Went Wrong !",
          color: "white"
        });
        // console.log(error);
      });

  }


  if (paid.onpaid == true) {
    // console.log("adsasd")
    setPaid({ ...paid, onpaid: false })
    // setOtherState({ ...otherState, submit_button: true })
    submit_detail()
  }

  // If any error occurs
  if (error) {
    return <div className='text-center mt-5'><h3>Error Occurred in processing payment.! Please try again.</h3></div>;
  }
  const pay_using_phonePay = () => {
    let full_api = config.apiUrl + `/user/payment_phone_pay`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: config.headers }).then(res => {

      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
      //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }

  const inputChangeHandle = (type) => {
    setStatePaymentMode({ ...statePaymentMode, type: type })
  }

  const dimension_detail = () => {
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({ ...dimensionState, companyDetail: res.data.companyDetail });
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
      //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }

  return (
    <div >
      <Header_nav />
      <div class="sub_header_inner">
      </div>

      <section class="py-4 bg-light">

        <div class="container width_custom_50">
          <h4 class="mt-5 mb-3">Checkout</h4>
          <section>
            <div class="card border-0 shadow-sm mt-3">
              <div class="card-body">
                <div class="row ">
                  <div class="col-12">
                    <div class="d-flex justify-content-end">
                      <div class="p-2"><p class="text-secondary mb-1 small">Sub Total</p></div>
                      <div class="p-2"><h5 class="mb-0">{dimensionState.companyDetail.map((subscriber) => (
                        <>{subscriber.set_currency}</>
                      ))}{location.state}</h5></div>
                    </div>
                  </div>
                  {/* <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <div class="p-2"><p class="text-secondary mb-1 small">Tax</p></div>
                            <div class="p-2"><h5 class="mb-0">$30.00</h5></div>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
            <div class="card mb-4 border-0 shadow-sm border-top-dashed">
              <div class="card-body text-end">
                <p class="text-secondary my-1">Net Payable</p>
                <h3 class="mb-0">{dimensionState.companyDetail.map((subscriber) => (
                  <>{subscriber.set_currency}</>
                ))}{location.state}</h3>
              </div>
            </div>
          </section>

          <section>
            {/* <div class="list-group list-group-flush my-3">
                        <a href="#" class="list-group-item ">
                            <div class="d-flex w-100 justify-content-between">
                                <h6 class="mb-2 text-dark">Pay with Paypal</h6>
                                <p class=""> <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked /></p>
                            </div>
                            <p class="text-secondary mb-2">Visa, Master, Debit & Credit card</p>
                        </a>
                        <a href="#" class="list-group-item ">
                            <div class="d-flex w-100 justify-content-between">
                                <h6 class="mb-2 text-dark">Pay with Skrill</h6>
                                <p class=""> <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" /></p>
                            </div>
                            <p class="text-secondary mb-2">Visa, Master, Debit & Credit card</p>
                        </a>
                    </div> */}
            <p>Select Payment Mode</p>
            <input type="radio" name="paymentMode" onChange={() => inputChangeHandle(1)} checked={statePaymentMode.type === 1} /> COD Wallet<br />
            <input type="radio" name="paymentMode" onChange={() => inputChangeHandle(2)} checked={statePaymentMode.type === 2} /> Paypal<br />
            <input type="radio" name="paymentMode" onChange={() => inputChangeHandle(3)} checked={statePaymentMode.type === 3} /> PhonePay

          </section>
          <br />
          <div class="d-grid" style={(statePaymentMode.type != 1) ? { visibility: "hidden" } : {}}>


            Available Balance {dimensionState.companyDetail.map((subscriber) => (
              <>{subscriber.set_currency}</>
            ))} {stateData.codBalance}
            {((stateData.codBalance - location.state) > 0) ? <button onClick={() => codWalletPaySubmit()} class="btn btn-primary" style={{ width: "100px" }}>Pay</button> : " (Insufficient Balance)"}


          </div>
          <div class="d-grid">



            {/* <a  href="my_wallet.php" class="btn btn-lg btn-primary text-white btn-block btn-rounded"><span>Pay Now</span><i class="material-icons">arrow_forward</i></a> */}
            <div style={(statePaymentMode.type != 2) ? { display: "none" } : {}} ref={paypalRef}></div>
            {(statePaymentMode.type == 3) ? <button onClick={pay_using_phonePay}>Pay using PhonePay</button> : ""}
          </div>





        </div>



      </section>
      <Footer />
    </div>
  )
}

export default Checkout



