import React from 'react'
import Customer_header from './Customer_header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Footer from './Footer'
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../config"
import Moment from 'react-moment';
import Header_nav from './Header_nav';
import download from 'downloadjs'

const Ndr_list = () => {
    let navigate = useNavigate()
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
    const [shipSelect, setShipSelect] = React.useState({ shipment_id: "",cancel_reason:"" });
    const [modalState, setModalState] = React.useState({ show: false });
    const [modaldtdtcState, setModaldtdcState] = React.useState({ show: false });
    const[dtdc , setdtdc] = React.useState();


    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000
  })

    // console.log("shipmentState",shipmentstate)
    React.useEffect(() => {
  
        shipment_list()
        dimension_detail()
    
      }, [])

    const shipment_list=(status)=>{
        let dataToSend = {customer_id : userData.customer_id ,  status:status
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/ndr_shipments';
     
     // console.log("headers =========> ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
      // // console.log("headers ====== " , config.headers)
          .then((res) => {
            // console.log("responseJson => ", res);
            setState({shipment_list:res.data.output,isLoading:false})
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }

    const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }

  

    const cancelModal = (ship) =>{
      // console.log("----ship:   ", ship.shipment_id);
      setShipSelect({cancel_reason:"",shipment_id:ship.shipment_id});
      setModalState({ show: true })
     
    }

    const cancelShipment = () =>{
      if(shipSelect.cancel_reason != ""){
      let full_api = config.apiUrl + `/shipment/shipment_cancel`;
      let sendData = {customer_id:userData.customer_id,shipment_id:shipSelect.shipment_id,cancel_reason:shipSelect.cancel_reason};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        Toast.fire({
          background: "#10b93b",
          type: 'success',
          title: res.data.message,
          color: "white"
      });
        if(res.data.status == "success"){

                setShipSelect({cancel_reason:"",shipment_id:""});
                setModalState({ show: false })
                shipment_list()
        }
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }else {
      Toast.fire({
        background: "#10b93b",
        type: 'success',
        title: "Enter Reason to Cancel.",
        color: "white"
    });
    }
    }

    const inputHandleChange = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setShipSelect({ ...shipSelect, [text]: value })
    }
    
   
   
  return (
    <div>
<Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
<Modal.Header closeButton>
                    <Modal.Title>Do you really want to Cancel ?</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <div className="row">
                      <div className="col-2 mb-3 mt-5" style={{marginLeft:"25px"}}>
                      <i class="fa fa-envelope " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
                      </div>
                        <div className="col-md-10 mb-3 mt-5 " style={{marginLeft:"-50px"}}>
                              <input className="form-control" id="first_name" type="text" placeholder="Enter Reason" name="cancel_reason" onChange={(e) => inputHandleChange(e)} required   style={{ background: "transparent", border: "none", borderBottom: "1px solid #000000",  outline:"none", boxShadow:"none"}}/>
                         </div>
                         
                         {/* <div className="col-2 mb-1 mt-3" style={{marginLeft:"25px"}}>
                         <i class="fa fa-lock " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
                      </div> */}
                         
                       <div>
                       
                    <button className="btn mt-3" style={{color: "#fff", backgroundColor: "#D65142", display:"inline-block",textAlign:"center",verticalAlign:"middle",position:"relative",boxSizing:"border-box",marginLeft:"190px",padding: "0.84rem 2.14rem",
    fontSize: "18px",fontWeight:"bold"}} onClick={() =>
      cancelShipment()
                        }>Yes</button>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
               
                  <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                        Close
                    </Button>
                              
                </Modal.Footer>
            </Modal>

            
  <Header_nav/>
<div class="sub_header_inner">
</div>

{state.isLoading==true?
        <ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />:
        <section class="py-4">
  <div class="container">

    <div class="card mb-3">
      <div class="card-body">
        <header class="">
            <div class="row mb-4 mt-3">
                <div class="col col-md">
                    <div class="d-flex">
                        <div class="">
                            <h4><a href=""><i class="material-icons">chevron_left</i></a>NDR List</h4>
                        </div>
                        <div class="ps-2 pt-2"><h6 class="text-muted">({state.shipment_list.length} Shipments)</h6></div>
                    </div>
                    
                </div>
                
            </div>
            
        </header>
      </div>
    </div>

    

    <div class="card ">
      <div class="card-body">
      

        <table class="table  shipment_box table-responsive">
            <thead>
              <tr class="custom_row_mb">
                <th>AWB Tracking Number</th>
                <th>Origin</th>
                <th>Destination</th>
                {/* <th>Sender</th>
                <th>Receiver</th> */}
                <th>Name of product</th>
                <th>Total Price</th>
                <th>Company</th>
                <th>Status</th>
                <th>Exception Info</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
                {state.shipment_list.map((sub)=>(
                <tr class="custom_row_mb">
                    <td>
                        
                        <h2><a href={"/shipment_detail/"+ sub.shipment_id}>#{sub.shipment_id}</a></h2>
                        <small class="text-muted"><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(sub.created*1000)}
            </Moment>  </small>
                    </td>
                    {/* {sub.sender.map((item)=>(
                    <td>

                        <h5>{item.pincode} - {item.city}</h5>
                        <small class="text-muted"> {item.country}</small>
                    </td>
                    ))}
                    {sub.receiver.map((i)=>(
                    <td>
                        <h5>{i.pincode} - {i.city}</h5>
                        <small class="text-muted">{i.country}</small>
                    </td>
                    ))} */}
                    {sub.sender.map((item)=>(
                    <td>
                        <h5>{item.name}</h5>
                    </td>
                    ))}
                     {sub.receiver.map((i)=>( 
                    <td>
                        <h5>{i.name}</h5>
                    </td>
                    ))}
                   
                    <td>
                    {sub.items.map((sub,index)=>(
                        <h5> {sub.item_name}<small class="text-muted"></small> </h5>))}
                        <small class="text-muted">Total Item - {sub.items.length}</small><br/>
                        <small class="text-muted">Total Weight - {sub.total_weight} ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</small>
                    </td>
                    
                    <td>
                        <h5>{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{sub.total_amount}</h5>
                    </td>
                    <td>
                        <h4>{sub.carrier_name}</h4>
                        {/* <h5>{sub.service_procvider}</h5> */}
                        <small class="text-muted">{sub.service_provider}</small>
                    </td>
                    <td>
                        <button type="button" class="btn btn-secondary btn-sm">{sub.status==0?"Pending":sub.status==1?"Booked":sub.status==2?"Picked Up":sub.status==3?"In Transit": sub.status==4?"Out For Delivery" : sub.status==5?"Delivered" : sub.status==6?"Cancelled":sub.status==7?"Failed":sub.status==8?"NDR":"Not Available"}</button>
                        <br/><br/>

                   {(sub.status==0 || sub.status==1)? <button type="button" class="btn btn-danger btn-sm" onClick={()=>cancelModal(sub)}>Cancel</button>:""}

                    </td>
                    <td>{sub.ndr_re_attempt!=undefined && sub.ndr_re_attempt!=""?sub.ndr_re_attempt:"0"} Attempts</td>
                    <td>
                       <button className='btn btn-primary btn-sm'>Re-Attempt</button>
                    </td>
                </tr>
                ))}
                
            </tbody>
          </table>
      </div>
    </div>

         
  </div>
</section>
}
<Footer/>
</div>
    )
}

export default Ndr_list
