import React, { useState, useEffect } from 'react'

import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

const After_ship = () => {
    const [shipstate, setShipstate] = React.useState([{menu_name:"",menu_link:"",buttonType: "add", error:{}}])
    const [newState, setNewState] = useState({ sub_domain: "", website_url: "" ,support_mobile:"", support_email:"", privacy_link:"", logo:"" ,  banner_link:"" ,banner_image_desktop:"" , banner_image_mobile :"" , });
    const[isLoading , setisLoading] = React.useState(false)
    const[message , setmessage ]= React.useState("")
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })

      React.useEffect(()=>{
        details()
      },[])
    const after_ship_save = () => {
       
        let full_api = config.apiUrl  + `/user/add_after_ship_details`;
        let bodyFormData = new FormData()
        console.log("bb", newState);
        bodyFormData.append("sub_domain", newState.sub_domain)
        bodyFormData.append("website_url", newState.website_url)
        bodyFormData.append("support_mobile", newState.support_mobile)
        bodyFormData.append("support_email", newState.support_email)
        bodyFormData.append("privacy_link", newState.privacy_link)
        bodyFormData.append("banner_link", newState.banner_link)
     

        if (newState.logo!=undefined &&  newState.logo!= '') {
            bodyFormData.append("logo", newState.logo[0]);
        }
        if (newState.banner_image_desktop!=undefined && newState.banner_image_desktop!= '') {
            bodyFormData.append("banner_image_desktop", newState.banner_image_desktop[0]);
        }if (newState.banner_image_mobile!=undefined && newState.banner_image_mobile!= '') {
            bodyFormData.append("banner_image_mobile", newState.banner_image_mobile[0]);
        }
        shipstate.forEach((item, index) => {
            bodyFormData.append(`shipstate[${index}][menu_name]`, item.menu_name);
            bodyFormData.append(`shipstate[${index}][menu_link]`, item.menu_link);
          });
        console.log("bb", bodyFormData);
        axios.post(full_api, bodyFormData, {
             headers: config.headers 
        }).then((res) => {
            console.log("res ", res);
           
            if(res){
                Swal.fire(
                    'Good job!',
                    res.data.message,
                    'success'
                  )
                  details()
             }
             else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    
                  })
                }
              // navigate('/categories')
            // let newCategory = res.data.category;
            //setState(state.concat([newCategory]))
        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         
          console.log("----error:   ", e);

        });
   
    }
    const inputHandleChange = (e) => {
        console.log("e", e);
        console.log("type", e.target.type);
        console.log("name", e.target.name);
        console.log("value", e.target.value);
        console.log("file", e.target.files);
        if (e.target.type == "file") {
            setNewState({ ...newState, [e.target.name]: e.target.files });
        } else {
            setNewState({ ...newState, [e.target.name]: e.target.value });
        }
    }
    function addRows(type, index) {
        // console.log("type    ", type, " . ", index)
      
        let ship_items = [...shipstate];
        // console.log("items    ", items)
        if (type == 1) {
         
          // console.log("newItem  =========>   ", newItem)
          let newShipItem = {menu_name:"",menu_link:"",buttonType: "remove", error:{}};
    
        
          ship_items.push(newShipItem);
        
          // console.log("items  =========>   ", items)
    
        
          setShipstate(ship_items)
        } else if (type == 2) {
         
          ship_items.splice(index,1)
         
          setShipstate(ship_items)
        }
      }
      const handleChange3 = (e, index) => {
        const { name, value } = e.target;
        const newShipstate = shipstate.map((sub, i) => {
          if (i === index) {
            return {
              ...sub,
              [name]: value,
              error: {
                ...sub.error,
                [name]: value.length === 0 // Update error state based on value length
              }
            };
          }
          return sub;
        });
        setShipstate(newShipstate);
      };

      

      const checkDomain = () => {
        setisLoading(true)
     let url = config.apiUrl + '/user/check_domain_availability';
        let sendData = {sub_domain:newState.sub_domain};
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // console.log("res ", res);  
            if(res.data.status==true){   
                setisLoading(false)
                setmessage(res.data.message)
               
            }
            else{
                setisLoading(false)
                Toast.fire({
                    background:"rgb(25, 135, 84)",
                    type: 'unsuccess',
                    title: "Something Went Wrong",
                    color:"white"
                  });
            }
    
        }).catch((e) => {
            setisLoading(false)
            Toast.fire({

                background:"rgb(25, 135, 84)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
    
        });
    }
    const details = () => {
        
     let url = config.apiUrl + '/user/after_ship_details';
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // console.log("res ", res);  
            if(res.data.status==true){   
              console.log("res === " , res.data.output)
              if(res.data.output!=undefined){
              if( Object.keys(res.data.output).length === 0){

              }
              else{
              setNewState({ sub_domain: res.data.output.sub_domain, website_url: res.data.output.website_url ,support_mobile:res.data.output.support_mobile, support_email:res.data.output.support_email, privacy_link:res.data.output.privacy_link, logo:res.data.output.logo ,  banner_link:res.data.output.banner_link ,banner_image_desktop:res.data.output.banner_image_desktop , banner_image_mobile :res.data.output.banner_image_mobile  });
              const updatedMenu = res.data.output.menu.map((item, index) => ({
                ...item,
                buttonType: index === 0 ? "add" : "minus"
              }));
              
              setShipstate(updatedMenu);
              }
            }
            }
            else{
               
              
            }
    
        }).catch((e) => {
           console.log("e == " , e)
            Toast.fire({

                background:"rgb(25, 135, 84)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
    
        });
    }
    
  return (
    <div>
           <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
        

        <section class="mb-3 mt-3 tools">
          <div class="container">
            <h3 class="pt-3">After Ship Settings</h3>
                {/* <nav aria-label="breadcrumb" class="pb-3">
                    <ol class="breadcrumb  ">
                      <li class="breadcrumb-item"><a href="./">Home</a></li>
                      <li class="breadcrumb-item " aria-current="page"><a href="tools.php">Tools</a></li>
                    </ol>
                </nav> */}
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link active" href="/customer/aftership">Tracking Page Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/customer/aftership_tracking_script">Tracking Script Settings</a>
                </li>
            </ul> 
            
                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Sub Domain Setting</h5>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Sub Domain Tracking Page Setting</h6>
                            <div class="row">
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Subdomain:</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Enter your appropiate sub domian name for tracking page" onChange={(e)=>inputHandleChange(e)} name='sub_domain' value={newState.sub_domain}/>
                                        <span class="input-group-text">app-dev.shiport.in</span>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-12 col-md-4">
                                            {isLoading?"":
                                            <a onClick={()=>checkDomain()} class="btn btn-primary btn-sm">Check Availablity</a>}
                                        </div>
                                        {message!=""?
                                        <div class="col-12 col-md text-end">
                                            <span class="badge bg-success"><small>{message}</small></span>
                                        </div>
                                        :""}
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                </div>

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Common Setting</h5>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Common Setting</h6>
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Website URL:</label>
                                    <input type="link" class="form-control" id="" placeholder="Enter Website URL" name="website_url" onChange={(e)=>inputHandleChange(e)} value={newState.website_url} />
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Support Phone No.:</label>
                                    <input type="number" class="form-control" id="" placeholder="Enter Support Phone No." name="support_mobile" onChange={(e)=>inputHandleChange(e)} value={newState.support_mobile} />
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Support Email:</label>
                                    <input type="email" class="form-control" id="" placeholder="Enter Support Email" name="support_email" onChange={(e)=>inputHandleChange(e)} value={newState.support_email}/>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Privacy Policy Link:</label>
                                    <input type="link" class="form-control" id="" placeholder="Enter Privacy Policy Link" name="privacy_link" onChange={(e)=>inputHandleChange(e)} value={newState.privacy_link}/>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Logo:</label>
                                    <input type="file" class="form-control" id="" placeholder="Enter Logo" name="logo" onChange={(e)=>inputHandleChange(e)}/>
                                </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                </div>

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Add Your Banner</h5>
                    <p>Grab the attention of your customers, indulge them in re-purchasing the products, and boost your conversion rate by using unique promotional banners on the tracking page.</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Banner Setting</h6>
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Link:</label>
                                    <input type="link" class="form-control" id="" placeholder="Enter Link" name="banner_link" onChange={(e)=>inputHandleChange(e)} value={newState.banner_link}/>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Banner Image (Desktop):</label>
                                    <input type="file" class="form-control" id="" placeholder="Enter Banner Image" name="banner_image_desktop" onChange={(e)=>inputHandleChange(e)} />
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Banner Image (Mobile):</label>
                                    <input type="file" class="form-control" id="" placeholder="Enter Banner Image" name="banner_image_mobile" onChange={(e)=>inputHandleChange(e)}/>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Header Menu Links</h5>
                    <p>Navigate customers on your desired webpage/s to drive more sales while customers track their orders.</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Add Header Menu Links</h6>
                            {shipstate.map((sub,index)=>(
                            <div class="row">
                                <div class="col-12 col-md mb-3">
                                    <label for="email" class="form-label">Menu Name:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Menu Name" name="menu_name"onChange={(e) => handleChange3(e,index)}  value={sub.menu_name}/>
                                </div>
                                <div class="col-12 col-md mb-3">
                                    <label for="email" class="form-label">Menu Link:</label>
                                    <input type="link" class="form-control" id="" placeholder="Enter Menu Link" name="menu_link" onChange={(e) => handleChange3(e,index)} value={sub.menu_link}/>
                                </div>
                                <div class="col-12 col-md-1 mb-3 pt-4">
                                {(sub.buttonType == "add") ?
                            //    <a href="#" class="btn btn-primary btn-xs"><i class="material-icons-outlined">add</i></a>
                                <a  class="btn btn-primary btn-xs"><i class="material-icons-outlined" onClick={() => addRows(1)} style={{ cursor: "pointer" }}>add</i></a>:
                                <a  class="btn btn-primary btn-xs"><i class="material-icons-outlined" onClick={() => addRows(2, index)} style={{ cursor: "pointer"}}>remove</i></a>
                                
                                  }
                                  </div>
                                
                                {/* <div class="col-12 col-md-1 mb-3 pt-4-5">
                                    <a  class="btn btn-primary btn-sm"><i class="fa fa-times" aria-hidden="true"></i></a>
                                </div> */}
                            </div>
                            ))}
                            {/* <div class="text-end pt-3">
                            {shipstate.map((sub,index)=>(
                                <>
                       { sub.buttonType == "add"?
                                <a href="#" class="btn btn-outline-secondary btn-sm" onClick={() => addRows(1)}>Add More</a>:
                                <a href="#" class="btn btn-outline-secondary btn-sm" onClick={() => addRows(2)}>Remove</a>}
                                </>    ))}
                            </div> */}
                          </div>
                        </div>
                    </div>
                </div>



                
                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    </div>
                    <div class="col-12 col-md">
                        <div class="pb-4">
                            <a onClick={()=>after_ship_save()} class="btn btn-primary">Save Changes</a>
                        </div>
                    </div>
                </div>
          </div>
        </section>
    </div>


    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default After_ship
    {/* <?php include 'meta.php';?>
    
    <?php include 'left_menu.php';?>

    <section class="home ">
        <?php include 'header.php';?> 

        

        <section class="mb-3 mt-3 tools">
          <div class="container">
            <h3 class="pt-3">After Ship Settings</h3>
                <nav aria-label="breadcrumb" class="pb-3">
                    <ol class="breadcrumb  ">
                      <li class="breadcrumb-item"><a href="./">Home</a></li>
                      <li class="breadcrumb-item " aria-current="page"><a href="tools.php">Tools</a></li>
                    </ol>
                </nav>
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link active" href="aftership.php">Tracking Page Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="aftership_tracking_script.php">Tracking Script Settings</a>
                </li>
            </ul> 
            
                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Sub Domain Setting</h5>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Sub Domain Tracking Page Setting</h6>
                            <div class="row">
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Subdomain:</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Enter your appropiate sub domian name for tracking page">
                                        <span class="input-group-text">-track.domain.com</span>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-12 col-md-4">
                                            <a href="#" class="btn btn-primary btn-sm">Check Availablity</a>
                                        </div>
                                        <div class="col-12 col-md text-end">
                                            <span class="badge bg-success"><small>This sub domain name is available</small></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                </div>

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Common Setting</h5>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Common Setting</h6>
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Website URL:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Website URL" name="email">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Support Phone No.:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Support Phone No." name="email">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Support Email:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Support Email" name="email">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Privacy Policy Link:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Privacy Policy Link" name="email">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Logo:</label>
                                    <input type="file" class="form-control" id="" placeholder="Enter Logo" name="email">
                                </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                </div>

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Add Your Banner</h5>
                    <p>Grab the attention of your customers, indulge them in re-purchasing the products, and boost your conversion rate by using unique promotional banners on the tracking page.</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Banner Setting</h6>
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Link:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Link" name="email">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Banner Image (Desktop):</label>
                                    <input type="file" class="form-control" id="" placeholder="Enter Banner Image" name="email">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Banner Image (Mobile):</label>
                                    <input type="file" class="form-control" id="" placeholder="Enter Banner Image" name="email">
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Header Menu Links</h5>
                    <p>Navigate customers on your desired webpage/s to drive more sales while customers track their orders.</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Add Header Menu Links</h6>
                            <div class="row">
                                <div class="col-12 col-md mb-3">
                                    <label for="email" class="form-label">Menu Name:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Menu Name" name="email">
                                </div>
                                <div class="col-12 col-md mb-3">
                                    <label for="email" class="form-label">Menu Link:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Menu Link" name="email">
                                </div>
                                <div class="col-12 col-md-1 mb-3 pt-4-5">
                                    <a href="#" class="btn btn-primary btn-sm"><i class="fa fa-times" aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <div class="text-end pt-3">
                                <a href="#" class="btn btn-outline-secondary btn-sm">Add More</a>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>



                
                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    </div>
                    <div class="col-12 col-md">
                        <div class="pb-4">
                            <a href="#" class="btn btn-primary">Save Changes</a>
                        </div>
                    </div>
                </div>
          </div>
        </section>
    </section>


    <?php include 'footer.php';?> */}