import React, { useState, createRef, useEffect } from "react";
import Shipment_steps from './Shipment_steps2'
import { useLocation, useNavigate,Navigate } from 'react-router-dom';
import axios from "axios";
import config from "../config"

const Shipment_created = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // console.log("location==>", location)
    const [state, setState] = useState({});
    useEffect(() => {
        setState(location.state)
    }, [])

    const PrivateRoute = () => {    
        // console.log("clicked==>")
        navigate("/",{ replace: true });
      };

    return (
        <div>
            <section class="mb-3 mt-3">
                <div class="container" style={{ backgroundColor: "#cccccc" }}>

                    <div className="row">
                        <div className="col-12 col-md-12">

                            <p style={{ marginTop: "30px", textAlign: "center", fontSize: "25px", fontWeight: "bold" }}>Your shipment has been booked</p>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: "40px" }}>
                                <img src="img/circle-check-icon-3.jpeg" alt="Los Angeles" style={{ width: "80px" }} />
                            </div>
                            <p style={{ marginTop: "20px", textAlign: "center", fontSize: "20px" }}>Thank You !</p>
                            <p style={{ marginTop: "10px", textAlign: "center", fontSize: "15px" }}>Your Tracking ID is: {state.masterTrackingNumber}</p>

                            <p style={{ marginTop: "20px", textAlign: "center", fontSize: "20px",textTransform:"uppercase" }}> <a href={location.state.pieceResponses[0].packageDocuments[0].url} target="_blank" style={{ marginTop: "10px", textAlign: "center", fontSize: "15px" }}>Print bar-code</a></p>

                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "30px" }}>
                                <button style={{ backgroundColor: "#ff6300", color: "#FFFFFF", padding: "5px 25px 5px 25px", borderWidth: "0px",borderRadius:"6px" }} onClick={() => PrivateRoute()}>Continue to new shipment</button>
                            </div>

                        </div>

                    </div>




                </div>
            </section>
        </div>
    )
}

export default Shipment_created