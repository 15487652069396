import React,{useRef} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';
import Barcode from 'react-barcode';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import ReactLoading from 'react-loading';
import config from "../config"
import { useReactToPrint } from 'react-to-print';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const Download_label = () => {
    const location = useLocation()
    const shipment_id = useParams()
    // console.log("shipment_id",shipment_id.shipment_id)

    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const componentRef = useRef();

    const[state,setState]= React.useState({shipment_list:[],total:0 , company_info:{}})
    // console.log("state",state)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[isloading , setisloading] = React.useState(true)

    React.useEffect(() => {
  
        shipment_list()
        dimension_detail()
       
      }, [])
    //   React.useEffect(() => {
    //     // Call onDownloadPDF when the component mounts
    //     onDownloadPDF();
    //   }, [state.shipment_list]);
    const onDownloadPDF = () => {
        const content = [];
      
        state.shipment_list.forEach((shipment, index) => {
          const shipmentContent = document.getElementById(`shipment-${index}`);
          console.log("shipmentContent == ", shipmentContent);
          if (shipmentContent) {
            const htmlContent = shipmentContent.innerHTML;
            console.log("htmlContent == ", htmlContent);
            try {
              const pdfContent = htmlToPdfmake(htmlContent);
      
              // Add this line to log the content
              console.log("pdfContent JSON == ", JSON.stringify(pdfContent));
      
              content.push(pdfContent);
      
              // Add a page break after each shipment
              content.push({ text: '', pageBreak: 'after' });
            } catch (error) {
              console.error(`Error converting HTML to pdfmake format for shipment ${index}:`, error);
            }
          }
        });
      
        const pdfDoc = {
          content,
          pageSize: { width: 4 * 72, height: 6 * 72 },
          pageOrientation: 'portrait',
        };
      
        // Log the pdfDoc before creating PDF
        console.log("pdfDoc == ", pdfDoc);
      
        pdfMake.createPdf(pdfDoc).download('labels.pdf');
      };
  
    const shipment_list=()=>{
        let dataToSend = {customer_id : userData.customer_id,shipment_id:shipment_id.shipment_id
    };
      let url = config.apiUrl + '/shipment/print_label_shipments';
       axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState({shipment_list:res.data.output,total : res.data.total , company_info:res.data.company_info})
            setisloading(false)
          })
          .catch((error) => {
          
          });    

    }
    const SenderAddress = () =>{
      if(state.shipment_list[0].items.length > 0){
        return state.shipment_list[0].items.reduce((previousValue, currentValue) =>{
          return previousValue.item_weight + currentValue.item_weight}) 
      }else {
        return 0
      }
     } 


     const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }
    const  FunctionComponent = (shipment_id) => {
      // console.log("shipment_id == " , shipment_id)
        return <Barcode value={shipment_id.value} />;
    };
    const  FunctionComponent1 = () => {
      return <Barcode value={state.shipment_list[0].carrier_tracking_number} />;
  };
  const tableStyle = {
    fontFamily: 'sans-serif',
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: '13px',
    verticalAlign: 'top',
  };

  const trStyle = {
    textAlign: 'left',
    padding: '18px',
  };

  const thTdStyle = {
    padding: '1px 10px',
    verticalAlign: 'top',
  };

  const evenRowStyle = {
    backgroundColor: '#fff',
  };

  const borderStyle = {
    border: '2px solid #000',
  };

  const centerStyle = {
    textAlign: 'center',
  };

  const monospaceStyle = {
    font: '20px monospace',
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };
  return (

<div>
<button className='btn btn-primary mb-5 mt-1 ' style={{alignItems:"center"}} onClick={onDownloadPDF}> Generate PDF</button>
  {isloading==false?
state.shipment_list.map((sub , index)=>(
          <>
       
           <div  key={index} >
         
       
<section  key={index}  className='lable_4_x_6' id={`shipment-${index}`}>
{/* <div  style={{pageBreakAfter: "always"}} key={index}></div> */}
<div class="page-break"> </div>

<table width="100%" border="0"  style={{borderCollapse: "collapse", border: "2px solid #000", fontSize: "10px" , lineHeight:"1px"}}>

   <tr class="">
  
                            <td colSpan='2'>
                                <h4 style={{fontSize:"12px" , margin:"0px"}}>Ship To:</h4>
                            </td>
                            
                            <td align="right">
                                <h4 style={{fontSize:"12px" , margin:"0px"}}><Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment></h4>
                            </td>
                        </tr>
                        
                        <tr class=""  >
                            <td colspan="3" style={{margin:"0px"}}>
                                <h3 style={{fontSize:"16px", marginBottom:"0px" , fontWeight:"600"}}>{sub.receiver[0].name}</h3>
                              <p style={{lineHeight:"1px", marginTop:"0px" , marginBottom:"0px"}}> {(sub.receiver[0].address)}<br/>
                                {sub.receiver[0].city}, {sub.receiver[0].state} {sub.receiver[0].pincode} &nbsp;
                                <strong style={{marginLeft:"24px" }}>Mob: {sub.receiver[0].mobile}</strong>
                                </p> 
                              
                            </td>
                        </tr>
                        <tr class="border">
                <td class="" style={{width:"60%"}}>
                    <h3 style={{fontSize:"12px",fontWeight:"500", margin:"0px"}}>Shipment Mode:</h3>
                    {sub.product_type_name?sub.product_type_name:"------"}
                    <h3 style={{fontSize:"12px" , fontWeight:"500",margin:"0px"}}>Payment Type:</h3>
                    {sub.payment_mode}
                </td>
                <td colspan="2" style={{width:"40%"}}>
                    {/* <h3 style={{fontSize:"14px", marginBottom:"0px" , fontWeight:"600"}}>DO NOT COLLECT CASH</h3>
                    <h3 style={{fontSize:"14px", marginBottom:"0px" , fontWeight:"600"}}>COD Amount:<br/> INR {sub.cod_amount}</h3> */}
                    {sub.payment_mode=="cod"?  <h3 style={{fontSize:"14px", marginBottom:"0px" , fontWeight:"600"}}>COD Amount: INR {sub.cod_amount}</h3 >:<h3 style={{fontSize:"14px", marginBottom:"0px" , fontWeight:"600"}}>DO NOT COLLECT CASH</h3> }
                </td>
            </tr>
   <tr class="" style={{margin:"0px"}} >  
                <td colspan="3" style={{ margin: "0px", padding: "0px", lineHeight: "1px" , alignItems:"center"}} >
                    {/* <h3 style={{fontSize:"16px", margin:"0px" , fontWeight:"600", padding:"0px" , lineHeight:"1px", textAlign:"center"}}>{sub.carrier_name}</h3> */}
                    <table style={{margin:"0px"}}>
                    <tr style={{border:"0" , margin:"0px"}}>
                        <td style={{marginLeft:"35px", marginTop:"0px", border:"0"}}></td>
                        <td style={{border:"0" , marginTop:"0px"}}>
                        <h3 style={{fontSize:"16px", margin:"0px" , fontWeight:"600", padding:"0px" , lineHeight:"1px", textAlign:"center"}}>{sub.carrier_name}</h3>
                        <Barcode value={sub.carrier_tracking_number} height={12} width={1} displayValue={true} fontSize={10}  margin={0}  />  
                        </td>
                        <td style={{border:"0" , margin:"0px"}}>ROUTE<br/><strong>---</strong></td>
                    </tr>
                   </table>
                    {/* <div style={{ marginLeft:"40%"}}>
                    <Barcode  value={sub.carrier_tracking_number} height={12} width={1} displayValue={true} fontSize={10} margin={0}  />
                    </div>
                   */}
                </td>
            </tr>
            
            <tr class="border-bottom" style={{margin:"0px" , padding:"0px"}}>
                            <td class="bold" >
                                Product
                            </td>
                            <td class="bold" >
                                Qty
                            </td>
                            <td class="bold">
                                Price
                            </td>
                        </tr>
        
                        {sub.items.map((item)=>(
                        <tr  class="border-bottom">
                            <td >
                            {item.item_name}
                            </td>
                            <td colspan="">
                            {item.item_quantity}
                            </td>
                            <td colspan="">
                                {item.item_value}
                            </td>
                        </tr>
                        ))}
        
                    
                        <tr  class="border-bottom">
                            <td>
                                Total
                            </td>
                            <td colspan="">
                                
                            </td>
                            <td colspan="">
                                {sub.totalValue}
                            </td>
                        </tr>
                        <tr>
                <td colspan="3" class="center border-bottom" style={{margin:"0px"}} >
                   <table style={{margin:"0px"}}>
                    <tr style={{border:"0" , margin:"0px"}}>
                        <td style={{marginLeft:"35px", marginTop:"0px", border:"0"}}></td>
                        <td style={{border:"0"}}>
                        <Barcode value={sub.order_number} height={12} width={1} displayValue={true} fontSize={10}  margin={0}  />  
                        </td>
                        <td style={{border:"0" , margin:"0px"}}></td>
                    </tr>
                   </table>
                {/* <span>
                  <Barcode value={sub.order_number} height={12} width={1} displayValue={true} fontSize={10}   margin={0}  />
                    </span> */}
              
                </td>
            </tr>
            <tr style={{margin:"0px"}}>
                <td colspan="3" class="" style={{margin:"0px"}}>
                    <p style={{margin:"0px"}}>Return Address: {(sub.return[0].address)}, {sub.return[0].city}-{sub.return[0].pincode}, {sub.return[0].state}, {sub.return[0].country}</p>
                </td>
            </tr>
          
           
                       

  
</table>


{/* </div> */}
  
    </section>



</div>

</>
)):<div style={{ display: 'flex', justifyContent: 'center', marginLeft:"80px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
<ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
</div>}

</div>
  )
}

export default Download_label
