import React from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import config from "../config"
import axios from 'axios';
import Select from 'react-select';
import { useRef } from 'react';

import Header_nav from './Header_nav'
import Shipping_form from './Shipping_form';
import { toast } from 'react-toastify';
import { getValue } from '@testing-library/user-event/dist/utils';

const Footer = () => {
  let navigate = useNavigate()
  const [popularstate, setpopularState] = React.useState({
    popular_country: [],
  isLoading:true  
  })
  const popular_country = () => {
  
    let full_api =config.apiUrl + '/home/get_popular_country';
    let sendData = {
      popular_country: {},
   
    };
    
  
    axios.post(full_api, sendData, { headers:config.headers}).then(res => {
      setpopularState({ ...popularstate,popular_country: res.data.popular_country ,isLoading:false });
   
      
    })
  }
  React.useEffect(() => {
    popular_country()
  }, [])
  

  return (
    <div>
     <footer>
    <section class="mb-3">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 mb-3 foo_logo">
            <img src="../img/logo/shiport_logo_2.png" class="img-fluid" />
          </div>
          <div class="col col-md">
            <h5>Useful tools & help</h5>
            <ul>
                <li><a href="">Get a Quote</a></li>
                <li><a href="">Parcel Tracking</a></li>
                <li><a href="">My Account</a></li>
                <li><a href="">Help Center</a></li>
            </ul>
          </div>
          <div class="col col-md mb-3">
            <h5>About Us</h5>
            <ul>
                <li><a href="">Our Company</a></li>
                <li><a href="">Our Services</a></li>
                <li><a href="">Terms & Conditions</a></li>
                <li><a href="">Privacy Policy</a></li>
                <li><a href="">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-12 col-md mb-3">
            <h5>Top Destinations</h5>
            <ul>
              {popularstate.popular_country.map((sub)=>(
                <li><a onClick={()=>navigate({pathname:'/get_quote'},{state:sub})} style={{cursor:"pointer"}}>Parcel Delivery to {sub.country_name}</a></li>
                ))}
            </ul>
          </div>
        </div>      
      </div>
    </section>

    <div class="py-2 pt-5">
        <div class="container text-center">
            <div class="row">
                <div class="col">
                    <div class="">
                        <p>Copyright &copy; 2022, All Right Reserved <a href="">Ship Rocket</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
    </div>
  )
}

export default Footer
