import React from 'react'
import config from "../config"


const Left_menu = () => {
  return (
    <div>
      <div class="sidebar">
        <div class="text-center">
            <div class="figure-menu shadow">
                <figure><img src="img/user1.png" alt=""/></figure>
            </div>
            <h5 class="mb-1 ">Ammy Jahnson</h5>
            <p class="text-mute small">Sydney, Australia</p>
        </div>
        <br/>
        <div class="row mx-0">
            <div class="col">
                <div class="card mb-3 border-0 shadow-sm bg-template-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <p class="text-secondary small mb-0">Wallet </p>
                                <h6 class="text-dark my-0">$285.00 <a href="my_wallet.php"><i class="material-icons">book</i> view</a></h6>
                            </div>
                            <div class="col-auto">
                                <a href="my_wallet.php" class="btn btn-default button-rounded-36 shadow"><i class="material-icons">add</i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="subtitle text-uppercase"><span>Menu</span></h5>
                <div class="list-group main-menu">
                    <a href="index.php" class="list-group-item list-group-item-action active">Dashboard</a>
                    
                    <a class="list-group-item list-group-item-action collapsed" data-toggle="collapse" href="#collapse_menu1" role="button" aria-expanded="false" aria-controls="collapse_menu1">My Profile <span class="float-right"><i class="fa fa-chevron-down" aria-hidden="true"></i></span> </a>
                    <div class="collapse" id="collapse_menu1">
                            <a href="profile.php" class="list-group-item list-group-item-action ms-3"><i class="material-icons">navigate_next</i> View Profile</a>
                            <a href="edit_profile.php" class="list-group-item list-group-item-action ms-3"><i class="material-icons">navigate_next</i> Edit Profile</a>
                            <a href="change_password.php" class="list-group-item list-group-item-action ms-3"><i class="material-icons">navigate_next</i> Change Password</a>
                    </div>
                    <a class="list-group-item list-group-item-action collapsed" data-toggle="collapse" href="#collapse_menu2" role="button" aria-expanded="false" aria-controls="collapse_menu2">My Shipments<span class="float-right"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                    <div class="collapse" id="collapse_menu2">
                            <a href="my_shipment.php" class="list-group-item list-group-item-action ms-3"><i class="material-icons">navigate_next</i> My Shipments</a>
                            <a href="create_shipment.php" class="list-group-item list-group-item-action ms-3"><i class="material-icons">navigate_next</i> Create Shipment</a>
                    </div>

                    

                    
                    <a href=".php" class="list-group-item list-group-item-action">Rate Calculator</a>
                    <a href="settings.php" class="list-group-item list-group-item-action">Settings</a>

                    

                    


                    <a href="xxxxx.php" class="list-group-item list-group-item-action mt-4">Logout</a>


                


                </div>
            </div>
        </div>

    </div>
    </div>
  )
}

export default Left_menu
