import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../config"


import ReactLoading from 'react-loading';


const Header_nav = () => {
  let navigate = useNavigate()
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  
  
  // console.log("userDetail ===>   ",userDetail);
  const [state, setState] = useState({ email: "",password:"" });
  const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"" });
  const [modalState, setModalState] = useState({ show: false });
  const [typeState, setTypeState] = useState({ type: "login" });
  const [userState, setUserState] = useState({ full_name: "" });
  const [styleState, setStyleState] = useState({ styles: 0 });
  const [trackState, setTrackState] = useState({tracking_no:""})
  const[trackresult,setTrackresult] = useState({})
  const[errorState,setErrorState] = useState()
  const[isLoading,setisLoading] = useState(false)
  const[modals,setModals] = React.useState({show: false})
  const [servicestate, setserviceState] = React.useState({
    web_body_list: [],
  isLoading:true  
  })
  
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  React.useEffect(()=>{
    get_wallet_balance()
    dimension_detail()
  },[])
  const get_wallet_balance=()=>{
    let dataToSend = {customer_id : userData.customer_id};
   
     // console.log("datatoSend",dataToSend)
     let url = config.apiUrl + '/user/get_wallet_balance';
     axios.post(url, dataToSend, { headers: config.headers })
         .then((res) => {
           // console.log("responseJson => ", res);
           if(res.data.status==true){
       setbalanceState({total_balance:res.data.find_customer_wallet})
    }
   else{
       const Toast = Swal.mixin({
           toast: true,
           position: 'bottom-end',
           showConfirmButton: false,
           timer: 3000
         })
         Toast.fire({
           background:"rgb(231, 91, 75)",
           type: 'Unsuccess',
           title: "Something Went Wrong !",
           color:"white"
         });
   } })
       
         .catch((error) => {
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
             // console.log(error);
         });    
   
   }
   const dimension_detail = () =>{
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }

  
  
  useEffect(() => {

    if(userDetail!= null) {
      let uDetail = JSON.parse(userDetail)
      setUserState({full_name:uDetail.full_name})
      config.UserId = uDetail.customer_id
    }
  }, [])
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  const inputHandleChange = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setState({ ...state, [text]: value })
  }
  const inputHandleChangeR = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setRegsterState({ ...regsterState, [text]: value })
  }
  const inputHandletracking = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setTrackState({ ...trackState, [text]: value })
  }

  const loginButton = (z) => {
   
    // console.log("state => ", state);
    if(state.email != "" && state.password != "") {
    let full_api = config.apiUrl + `/user/login_api`;
    let sendData = state;
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
            
            setModalState({ show: false })
             window.location.reload(false);
           // navigate('/')
        }else {
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: "Email or password incorrct",
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please fill both fields",
      color:"white"
    });
  }
}
const regitserButton = () =>{
  // console.log("regsterState", regsterState);
  if(regsterState.email != "" && regsterState.password != ""  && regsterState.full_name != "") {
    let full_api = config.apiUrl + `/user/register_api`;
    let sendData = regsterState;
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            setModalState({ show: false })
           // navigate('/')
        }else {
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: res.data.message ,
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please fill all fields",
      color:"white"
    });
  }

}
const trackButton = () =>{
  setisLoading(true)
         if(trackState.tracking_no!=""){

        setisLoading(false)
         navigate(`/tracking/`+ trackState.tracking_no)
         }else{
          setisLoading(false)
         }
  
}
const logOutButton = () =>{
  // console.log("userDetail ===>   ");

  let full_api = config.apiUrl + `/user/user_logout`;
    let sendData = {customer_id:config.UserId};
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.status==true) {
          localStorage.removeItem('ship_rocket_user');
          userDetail = null;
          setUserState({full_name:""})
          navigate('/')
        }else {
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: res.data.message ,
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  

}
const changestyle=()=>{
  setStyleState({styles:1})
}
const changeloginstyle=()=>{
  setStyleState({styles:0})
}
const openregister=()=>{
  setModalState({show:true})
  setStyleState({styles:1})
}
const openlogin=()=>{
  setModalState({show:true})
  setStyleState({styles:0})
}


const axios_get_api = () => {
  let url = config.apiUrl + '/home/web_body_list';
 
  let sendData = {
  web_body_list: {},
  dstatus:1
  };
  

  axios.post(url, sendData, { headers: config.headers }).then(res => {
    
    setserviceState({ ...servicestate,web_body_list: res.data.output ,isLoading:false });
  
  })
}
React.useEffect(() => {
  axios_get_api()
}, [])

// React.useEffect(()=>{
//   window.addEventListener("keydown", function(e) {
//     if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
//         e.preventDefault();
//     }
// }, false);
// },[])

  return (

<div>
<header>
        <nav class="navbar navbar-expand-sm nav_custom py-4">
        <div class="container">
            <a class="navbar-brand" href="/"><img src="../img/logo/shiport_logo.png" class="img-fluid" /> </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <i class="material-icons-outlined brandicn" >menu</i>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav  ms-auto text-end-c">
                <li class="nav-item">
                <a class="nav-link active" href="/">Home</a>
                </li>
                {/* <li class="nav-item">
                <a class="nav-link" href="/get_quote">Get a Quote</a>
                </li> */}
                  <li class="nav-item">
                  {(userDetail == null) ?
                <a class="nav-link" href="/login">Book a Shipment</a>:
                <a class="nav-link" href="/ship_1">Book a Shipment</a>}

                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Services</a>
                    <ul class="dropdown-menu">
                      {servicestate.web_body_list.map((sub)=>(
                        <li><a class="dropdown-item" href="/">{sub.body_heading}</a></li>
                      
                        ))}
                    </ul>
                </li>
                <li class="nav-item">
                <a class="nav-link" href="/">Help</a>
                </li>
            </ul>
            

            <div class="d-flex">
            {(userDetail == null) ?
                <div class="ps-2">
                    <a href="/login" class="btn btn-light">Login</a>
                </div>:""}
                {(userDetail == null) ?
                <div class="ps-2">
                    <a href="/register" class="btn btn-light">Register</a>
                </div>:""}
                {userDetail!= null?
                <div class="ps-2">
                    <div class="btn-group">
                        <div class="btn-group">
                            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">My Account </button>
                            <div class="dropdown-menu">
                                <div class="ps-3 pe-2">
                                    <h6>{userData.full_name}</h6>
                                </div>
                                <hr/>
                             <a class="dropdown-item " style={{cursor:"pointer"}} href="/my_shipments">My Shipment</a>
                             <a class="dropdown-item " style={{cursor:"pointer"}} href="/my_orders">My Orders</a>
                             <a class="dropdown-item" style={{cursor:"pointer"}} href="/my_address_book">My Address Book</a>
                             <a class="dropdown-item" style={{cursor:"pointer"}} href="/ndr_list">NDR List</a>
                             <a class="dropdown-item" style={{cursor:"pointer"}} href="/weight_discrepancies">Weight Discrepancies</a>
                             <a class="dropdown-item" style={{cursor:"pointer"}} href="/my_cod_wallet">My COD Wallet</a>
                             <a class="dropdown-item" style={{cursor:"pointer"}} href="/profile">My Profile</a>
                             <a class="dropdown-item" style={{cursor:"pointer"}} href="/my_address_book">My Address Book</a>
                            {userData.user_type==2? <a class="dropdown-item" style={{cursor:"pointer"}} href="/developer_settings">Developer Account</a>:""}
                             <a class="dropdown-item" style={{cursor:"pointer"}} href="/change_password">Change Password</a>

                                <hr/>
                            <a class="dropdown-item" style={{cursor:"pointer"}} onClick={() => logOutButton()}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>:""}
                {userDetail!= null?
                <div class="ps-2">
                    <a href="my_wallet" class="btn btn-outline-light">Wallet Bal {dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{balancestate.total_balance.map((sub)=>(
                       parseFloat(sub.wallet_balance).toFixed(2)))}</a>
                </div>:""}
                {/* <div className="ps-2">
                <input type="text" className="form-control" placeholder="Enter Tracking Number" name="tracking_no" onChange={(e) => inputHandletracking(e)} value={trackState.tracking_no} onFocus={()=>setErrorState("")}></input>
                </div> */}
              
                <div class="ps-2">
                  
                {isLoading==false?<a  onClick={()=>setModals({show:true})} class="btn btn-outline-light">Track a Parcel</a>:<ReactLoading type={"spin"} color={"#dc291e"}  height={40} width={40} />}
                </div>
               

            </div>

            </div>
        </div>
        <Modal show={modals.show} onHide={()=>setModals({show:false})} >
                                    <Modal.Header closeButton>
                                      <Modal.Title>Track Your Shipment Now</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div class="form-floating mb-3 mt-3">
                  <input type="text" class="form-control" name="tracking_no" onChange={(e) => inputHandletracking(e)} value={trackState.tracking_no}/>
                  <label for="email">Enter AWB Shipment Tracking number</label>
                </div>
                <div class="d-grid pb-5">
                  <a onClick={()=>trackButton()} class="btn btn-main btn-block">Track Your Shipment</a>
                </div>
                                    </Modal.Body>
                                  
                                  
                                   

                                    {/* edit modal */}
                                  </Modal>
        </nav>
    </header>

    
</div>
  )
}

export default Header_nav
