import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import { useNetworkState } from 'react-use';
const Bulk_order_log = () => {
const{log_id} = useParams() 
const isOnline = useNetworkState();
console.log("isONline  === " ,isOnline )
const[state,setState]= React.useState([])
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    const shipment_list=(status)=>{
        let dataToSend = {log_id : log_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState(res.data.output)
            // setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }

    React.useEffect(()=>{
        shipment_list()
    },[])

  return (
    <div className='wrapper'>
   <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>

        <section class="mb-3 pt-4">
          <div class="">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <h3><a href="/customer/orders"><i class="fa fa-chevron-left" aria-hidden="true"></i></a>  Bulk Orders Log</h3>
              </div>
            </div> 

            
               
          </div>
        </section>


        <section class="mb-3">
          <div class="">
            <div class="table-responsive">
                <table class="table table_box">
                    <thead  class="">
                        <tr class="brd">
                        <th>Log ID</th>
                        <th>Date/Time</th>
                        <th>Orders</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {state.map((sub)=>(

                       
                        <tr class="brd">
                            <td>
                                <h5><a href={`/customer/bulk_orders_log_detail/${sub.log_id}`}>{sub.log_id}</a></h5>
                            </td>
                            <td>
                             <small>{sub.date} | {sub.time}</small>
                            </td>
                            <td>
                                <h2><strong>{sub.uploaded_records}</strong><small class="text-primary">/{sub.total_records}</small></h2>
                            </td>
                            <td>
                                {sub.status==0?
                                <h2><span class="badge rounded-pill bg-info fw-400">Running</span></h2>:
                                <h2><span class="badge rounded-pill bg-success fw-400">Completed</span></h2>}
                            </td>
                            <td>
                                <h5><a href={`/customer/bulk_orders_log_detail/${sub.log_id}`}>View Log</a></h5>
                            </td>
                        </tr>
                         ))}
                    </tbody>
                </table>
            </div>     
          </div>
        </section>
        
    

    </div>
    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Bulk_order_log
