import React from 'react'
import Customer_header from './Customer_header'
import axios from 'axios';
import config from '../config';
import Swal from 'sweetalert2';
import Header_nav from './Header_nav';
const Change_password = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const [state, setState] = React.useState({customer_id:userData.customer_id, old_password: "", new_password: "", confirm_password: "" });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const password_change = () => {
        // console.log("ss")
        if (state.new_password == state.confirm_password) {
            let full_api = config.apiUrl + `/user/update_user_password`;
            let sendData = state;

            // console.log("bb", sendData);
            axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
                // console.log("res ", res);
                if(!res.data.isPasswordChanged) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.message,

                })
            }else {
                Swal.fire({
                    icon: 'success',
                    title: 'Success...',
                    text: res.data.message,

                })

            }

            }).catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })

            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Password not match!',

            })
        }
    }
  return (
    <div >
        <Header_nav/>
        <div class="sub_header_inner">
</div>
         <div class="container width_custom bg-white mt-3 p-3" >
            
            <h4 class="mt-5 mb-3"><span class="font-weight-light">Change </span>Password</h4>
    
                <div class="row">
               
                    <div class="col-12 col-md-12">
                        <div class="form-group float-label active">
                        <input class="form-control" id="address" type="text" required name="old_password" onChange={(e) => handleChange(e)} autoComplete="off" />
                            <label class="form-control-label">Current Password</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group float-label active">
                        <input class="form-control" id="address" type="text" required name="new_password" onChange={(e) => handleChange(e)} autoComplete="off" />
                            <label class="form-control-label">New Password</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group float-label active">
                        <input class="form-control" id="address" type="text" required name="confirm_password" onChange={(e) => handleChange(e)} autoComplete="off"/>
                            <label class="form-control-label">Confirm New Password</label>
                        </div>
                    </div>
                    
                </div>
   
                
    
                <br/>
                <div class="d-grid">
                    <a   class="btn btn-lg btn-main text-white btn-block btn-rounded"><span onClick={()=>password_change()}>Update Password</span><i class="material-icons">arrow_forward</i></a>
                </div>
            </div>
           
    </div>
  )
}

export default Change_password
