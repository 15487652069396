import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
// import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

const Channel_add = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[state,setState] = React.useState([])
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    React.useEffect(()=>{
        getChannelList()
    },[])
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
    const getChannelList = () => {


        let url = config.apiUrl + '/user/channel_find';
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // console.log("res ", res);  
            if(res.data.status==true){         
            setState(res.data.output)
            }
            else{
                Toast.fire({
                    background:"rgb(25, 135, 84)",
                    type: 'unsuccess',
                    title: "Something Went Wrong",
                    color:"white"
                  });
            }
    
        }).catch((e) => {
            Toast.fire({
                background:"rgb(25, 135, 84)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
    
        });
    }
  return (
    <div >
    
    <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>

        <section class="mb-3  tools">
        <div class="">
            <div class="row ">
                <div class="col-12 col-md">
                    <h2>Connect a Channel</h2>
                </div>
                <div class="col-12 col-md-4 text-end">
                   
                </div>
            </div>
            <div class="row chanel_list">
                {state.length>0?
                state.map((sub)=>(
                <div class="col-12 col-md-4 mb-3">
                        <div class="card  shadow-sm ">
                            <div class="card-body">
                                <div class="text-center">
                                    <figure>
                                        <img src={`https://cdn.shreebanshidharlogistics.com/channel_images/${sub.channel_img}`} class="img-fluid" style={{height:"100px"}}/>
                                    </figure>
                                    <h4>{sub.channel_name}</h4>
                                </div>

                            <hr/>
                            <div class="text-center">
                                    <a href={`/customer/channel_integrate/${sub.channel_id}`} class="btn btn-primary btn-sm">Integrate</a>
                            </div>
                        </div>
                    </div>
                </div>
       )) :""}   
            </div>
        </div>  
        </section>
        
  


    {/* <?php include 'footer.php';?> */}
    </div>
    </div>
  )
}

export default Channel_add
