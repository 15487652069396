import React from 'react'
import Customer_header from './Customer_header'
import config from '../config'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Header_nav from './Header_nav'
import Footer from './Footer'
const Customer_profile = () => {
   
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[infostate,setInfoState]= React.useState({customer_info:[],isLoading:true})

    const customer_info = () => {

        let full_api = config.apiUrl + `/user/customer_info_find`;
        let sendData = {
         customer_id:  userData.customer_id,
       
        };
    
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            setInfoState({ ...infostate,  customer_info: res.data.output,isLoading:false});
          // console.log("response user", res);
        }).catch((e) => {
    
          // console.log("----error:   ", e);
        })
      }

      React.useEffect(() => {
       
        customer_info()
      }, [])
  return (
    <div >
        <Header_nav/>
        <div class="sub_header_inner">
</div>

<section class="py-4 bg-light">
{infostate.customer_info.map((sub)=>(
   <div class="container width_custom bg-white mt-3  p-3">
   <div class="text-center">
   <h3 class="mb-1 ">{sub.full_name}</h3>
           <p class="text-secondary">{sub.city}, {sub.country}</p>
   </div>
   <br/>
   

   
   


   <h6 class="subtitle">Contact Information</h6>
   <dl class="row mb-4">
           <dt class="col-3 text-secondary font-weight-normal">Email</dt>
           <dd class="col-9">{sub.email}</dd>
           <dt class="col-3 text-secondary font-weight-normal">Phone</dt>
           <dd class="col-9">{sub.mobile!=undefined && sub.mobile!=""?sub.mobile:""}</dd>
       </dl>

       <h6 class="subtitle">Address</h6>
       <p class="mb-4">{sub.address!=undefined && sub.address!=""? sub.address:""}<br/>
       {sub.city!=undefined && sub.city!=""? sub.city:""}<br/>
       {sub.state!=undefined && sub.state!=""? sub.state:""}<br/>
       {sub.country!=undefined && sub.country!=""? sub.country:""}
       </p>
       
       <div class="d-grid">
           <a  href={"/edit_profile/"+sub.customer_id} class="btn btn-lg btn-main text-white btn-block btn-rounded"><span>Edit Profile</span><i class="material-icons">arrow_forward</i></a>
       </div>
   
       
       <br/>

</div>
   ))} 
           
        </section>
        <Footer/>
    </div>
  )
}

export default Customer_profile


