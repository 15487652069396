import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useNetworkState } from 'react-use';
const Billing_nav = (props) => {
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
   
    const { value } = props;
    // console.log("props === " , props)
    const [activeTab, setActiveTab] = React.useState(7);
    React.useEffect(() => {
        // Determine the active tab based on the value prop or the current route location
        if (value !== undefined) {
          setActiveTab(value);
        }
      }, [value]);
  return (
    <div>
      <section class="mb-3 ">
          <div class="">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <h2>Billing</h2>
              </div>
              <div class="col-12 col-md">
                
              </div>
            </div> 
            <div>
                <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a className={activeTab === 7 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }} href="/customer/billing_shipping_charges">Shipping Charges</a>
                    </li>
                    <li class="nav-item">
                      <a className={activeTab === 8 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }} href="/customer/billing_cod_remittance">COD Remittance</a>
                    </li>
                    <li class="nav-item">
                      <a className={activeTab === 9 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }} href="/customer/billing_wallet">Wallet History</a>
                    </li>
                    {/* <li class="nav-item">
                      <a class="nav-link" href="billing_invoice.php">Invoices</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Current Plan Details</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Passbook</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Credit Receipt</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">International Shipping Bill</a>
                    </li> */}
                </ul>
            </div>     
          </div>
        </section>
    </div>
  )
}

export default Billing_nav
