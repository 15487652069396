import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { useNetworkState } from 'react-use';

// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"

const Settings = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
   
  return (
    <div>
      <Left_menu value={2}/>
      
      <div class="home-section">
         
          <Header/>

        <section class="mb-3 mt-3 tools">
          <div class="container">
            <h3 class="py-3">Settings</h3>
            <div class="row">
                <div class="col-12 col-md-4 mb-3">
                    <a href="/customer/warehouse" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div class="iconset">
                                    <div class="icon color-3">
                                        <i class="material-icons-outlined">business</i>
                                    </div>
                                </div>
                                <h5>Warehouse</h5>
                                <small>Manage all of your warehouses from here</small>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <a href="" class="" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div class="iconset">
                                    <div class="icon color-3">
                                        <i class="material-icons-outlined">qr_code_2</i>
                                    </div>
                                </div>
                                <h5>Lable Settings</h5>
                                <small>Customize your lable settings</small>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <a href="" class="" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div class="iconset">
                                    <div class="icon color-3">
                                        <i class="material-icons-outlined">api</i>
                                    </div>
                                </div>
                                <h5>Developer APIs</h5>
                                <small>Find out your developer access settings</small>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <a href="/customer/channel_list" class="" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div class="iconset">
                                    <div class="icon color-3">
                                        <i class="material-icons-outlined">group_work</i>
                                    </div>
                                </div>
                                <h5>Channel</h5>
                                <small>Checkout all avaibale channel partners</small>
                            </div>
                        </div>
                    </a>
                </div>
            </div>      
          </div>
        </section>
        
</div>


    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Settings
