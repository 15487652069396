import React from 'react'
import Header from './Header'
import Left_menu from './Left_menu'

const Allocation_rule = () => {
  return (
    <div>
        <Left_menu value={10}/>
       <section class="home ">
        {/* <?php include 'header.php';?>  */}
<Header/>
        

        <section class="mb-3 mt-3 tools">
          <div class="container">
            <div class="row">
                <div class="col-12 col-md">
                    <h3 class="py-3">Create Order Allocation Rule</h3>
                    <nav aria-label="breadcrumb" class="">
                        <ol class="breadcrumb  ">
                          <li class="breadcrumb-item " aria-current="page"><a href="allocation.php">Order Allocation Engine</a></li>
                          <li class="breadcrumb-item " aria-current="page"><a href="tools.php">Tools</a></li>
                          <li class="breadcrumb-item"><a href="./">Home</a></li>
                        </ol>
                    </nav>
                </div>
                <div class="col-12 col-md-6 ">
                    
                </div>
            </div>
                

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Add New Shipping Rule</h5>
                    <p>From this section you can create/update new shipping allocation rule for your orders. This rule will help you to automate your order booking process.</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Add New Shipping Rule</h6>
                            <div class="row">
                                
                                <div class="col-12 col-md-8 mb-3">
                                    <label for="email" class="form-label">Rule Name:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Rule Name" name="email" />
                                </div>
                                <div class="col-12 col-md-4 mb-3">
                                    <label for="email" class="form-label">Rule Priority:</label>
                                    <input type="number" class="form-control" id="" placeholder="Enter Rule Priority" name="email" />
                                </div>
                                <div class="col-12 col-md-12 mb-3">
                                    <hr />
                                    <label for="email" class="form-label">Rule Type:</label>
                                    <div>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="" id="" value="option1" checked />
                                        <label class="form-check-label" for="">B2c</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="" id="" value="option2" />
                                        <label class="form-check-label" for="">B2b</label>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                                <div class="col-12 col-md-12 mb-3">
                                    <div>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="" id="" value="option1" checked/>
                                        <label class="form-check-label" for="">Match Any of the Below</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="" id="" value="option2"/>
                                        <label class="form-check-label" for="">Match All of the Below</label>
                                        </div>
                                    </div>
                                    <div class="card bg-light my-3  border-primary">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md-6 mb-2">
                                                <select class="form-select form-select-sm">
                                                  <option>Please Select</option>
                                                  <option>Payment Mode</option>
                                                  <option>Order Amount</option>
                                                  <option>Pickup Pincode</option>
                                                  <option>Delivery Pincode</option>
                                                  <option>Zone</option>
                                                  <option>Weight</option>
                                                  <option>Product Name</option>
                                                  <option>Product SKU</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 mb-2">
                                            <select class="form-select form-select-sm">
                                                  <option>Please Select</option>
                                                  <option>Is</option>
                                                  <option>Is not</option>
                                                  <option>Starts with</option>
                                                  <option>Greater than</option>
                                                  <option>Less than</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-12 mb-2">
                                                <textarea class="form-control form-control-sm" rows="2" id="comment" name="text" placeholder="Enter details"></textarea>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-center">
                                        <a href="#" class="btn btn-primary btn-xs"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                    </div>    
                                    <hr/>
                                </div>
                                <div class="col-12 col-md-12 mb-3">
                                    <h6>Courier Priority</h6>
                                    <div class="card bg-light my-3  border-primary">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md-6 mb-2">
                                                <label for="email" class="form-label">Priority 1:</label>
                                                <select class="form-select form-select-sm">
                                                  <option>Please Select</option>
                                                  <option>DTDC Surface</option>
                                                  <option>DTDC Air</option>
                                                  <option>DHL Less then 1KG</option>
                                                  <option>Fedex Above 10KG</option>
                                                  <option>Delhivery 1KG</option>
                                                  <option>Delhivery 5KG</option>
                                                  <option>Delhivery 10KG</option>
                                                  <option>ECOM Express</option>
                                                  <option>Xpressbees</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 mb-2">
                                                <label for="email" class="form-label">Priority 2:</label>
                                                <select class="form-select form-select-sm">
                                                  <option>Please Select</option>
                                                  <option>DTDC Surface</option>
                                                  <option>DTDC Air</option>
                                                  <option>DHL Less then 1KG</option>
                                                  <option>Fedex Above 10KG</option>
                                                  <option>Delhivery 1KG</option>
                                                  <option>Delhivery 5KG</option>
                                                  <option>Delhivery 10KG</option>
                                                  <option>ECOM Express</option>
                                                  <option>Xpressbees</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 mb-2">
                                                <label for="email" class="form-label">Priority 3:</label>
                                                <select class="form-select form-select-sm">
                                                  <option>Please Select</option>
                                                  <option>DTDC Surface</option>
                                                  <option>DTDC Air</option>
                                                  <option>DHL Less then 1KG</option>
                                                  <option>Fedex Above 10KG</option>
                                                  <option>Delhivery 1KG</option>
                                                  <option>Delhivery 5KG</option>
                                                  <option>Delhivery 10KG</option>
                                                  <option>ECOM Express</option>
                                                  <option>Xpressbees</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 mb-2">
                                                <label for="email" class="form-label">Priority 4:</label>
                                                <select class="form-select form-select-sm">
                                                  <option>Please Select</option>
                                                  <option>DTDC Surface</option>
                                                  <option>DTDC Air</option>
                                                  <option>DHL Less then 1KG</option>
                                                  <option>Fedex Above 10KG</option>
                                                  <option>Delhivery 1KG</option>
                                                  <option>Delhivery 5KG</option>
                                                  <option>Delhivery 10KG</option>
                                                  <option>ECOM Express</option>
                                                  <option>Xpressbees</option>
                                                </select>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-center">
                                        <a href="#" class="btn btn-primary btn-xs"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                    </div> 
                                </div>
                            </div>
                          </div>
                        </div>
                        
                    </div>
                </div>


                
                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    </div>
                    <div class="col-12 col-md">
                        <div class="pb-4">
                            <a href="#" class="btn btn-primary">Save Changes</a>
                        </div>
                    </div>
                </div>
          </div>
        </section>
    </section>
    </div>
  )
}

export default Allocation_rule
