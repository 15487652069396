import React, { useState, useEffect } from 'react'
import Shipment_steps from './Shipment_steps'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import config from "../config"
import Header_nav from './Header_nav';
import axios from 'axios';
const Ship_2 = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    let navigate = useNavigate();
  
   const location = useLocation()
 // // console.log("location==>122", location)
  let  parsed = queryString.parse(location.search);
  // // console.log("parsed",parsed);
  const [orderNumber, setOrderNumber] = useState('');
  const[codlimit , setcodlimit]=useState({limit:0})
  const[parceltype,setparceltypeState] = useState({parcel:false})
  const[documenttype,setdocumenttypeState] = useState({document:false})
  const[state,setState] = useState({  parcel_type:"" , order_date : "" , order_number:"" })
  // // console.log("state==== " , state)
  const [rowstate, setRowState] = React.useState([{ weight: "", height: "", width: "", length: "", buttonType: "add",error:{} }])
  const [shipstate, setShipstate] = React.useState([{shipment_content:"",total_value:"",quantity:"" , error:{}}])
  // // console.log("rowstate ==== " , rowstate)
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const[paymentmode,setpaymentmode]= useState({mode:1})
  // // console.log("paymentmode",paymentmode)
  const [codvalue,setcodvalue] = useState({cod_amount:0 , err:{}})
  // // console.log("codvalue",codvalue)
  const [insurancestate, setinsurance] = useState({ insurance: 0 })
  useEffect(() => {
    generateOrderNumber();
    dimension_detail();
  }, []);

  const dimension_detail = () =>{
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      // // // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      // // console.log("----error:   ", e);
    })
  }

  const generateOrderNumber = () => {
    // Generate the order number logic
    const randomNumber = Math.floor(Math.random() * 1000000);
    const formattedOrderNumber =  + randomNumber;

    setOrderNumber(formattedOrderNumber);
    setState({...state , order_number:formattedOrderNumber})
  }
  const handleChange4 = (e) => {
    // // console.log("e ", e.target.name);
    // // console.log("e checked", e.target.checked);
    if (e.target.checked == true) {
      setinsurance({ ...insurancestate, insurance: 1 })
    }
    else {
      setinsurance({ ...insurancestate, insurance: 0 })
    }
  }
  const handleChange=(e)=>{
    // // // console.log("namess",e.target.name)
    // // // console.log("value",e.target.value)
   
    let names = e.target.name
    let value= e.target.value
   
    if(e.target.type=="text"){
    setState({...state,[e.target.name]:value})
    }
    else if(e.target.type=="radio"){
        if(e.target.checked==true){
          
             if(e.target.name=="document"){
              setparceltypeState({...parceltype,parcel:false})
              setdocumenttypeState({...documenttype,document:true})
              setState({...state,parcel_type:"Document"})
            }
            else{
              setparceltypeState({...parceltype,parcel:true})
              setdocumenttypeState({...documenttype,document:false})
              setState({...state,parcel_type:"Parcel"})
            }
        }
    }
    else if(e.target.type=="number"){
      setState({...state,[e.target.name]:value})
    }
    else if(e.target.type=="date"){
        setState({...state,[e.target.name]:value})
       
    }
}
function addRows(type, index) {
    // // console.log("type    ", type, " . ", index)
    let items = [...rowstate];
    let ship_items = [...shipstate];
    // // console.log("items    ", items)
    if (type == 1) {
      let newItem = { weight: "", height: "", width: "", length: "", buttonType: "remove",error:{} };
      // // console.log("newItem  =========>   ", newItem)
      let newShipItem = {shipment_content:"",total_value:"",quantity:"" , error:{}};

      items.push(newItem);
      ship_items.push(newShipItem);
    
      // // console.log("items  =========>   ", items)

      setRowState(items)
      setShipstate(ship_items)
    } else if (type == 2) {
      items.splice(index, 1);
      ship_items.splice(index,1)
      setRowState(items)
      setShipstate(ship_items)
    }
  }
  const handleChange2 = (e, index) => {
    //  // // console.log("eee",e.target.value)
    let items = [...rowstate];
    let names = e.target.name;
    let value = e.target.value;
    if(value > 0) {
      items[index].error[names] = false;
     }else{
      items[index].error[names] = true;
    }
   // // // console.log(items[index][names])
   items[index][names] = value;
   setRowState(items)
    //setState({ ...state, [names]: value })
  }
  const handleChange3 = (e, index) => {
     // // console.log("eee",e.target.value.length)
    let items = [...shipstate];
    let names = e.target.name;
    let value = e.target.value;
    if(e.target.type=="number"){
    if(value > 0 ) {
      items[index].error[names] = false;
    }else{
      items[index].error[names] = true;
    }
}
else{
    if(value.length > 0 ) {
        items[index].error[names] = false;
      }else{
        items[index].error[names] = true;
      }
}
   // // // console.log(items[index][names])
    items[index][names] = value;
    setShipstate(items)
    //setState({ ...state, [names]: value })
  }
  const payment_mode =(e)=>{
    if(e.target.name=="prepaid"){
      setpaymentmode({mode:1})
    }
    else{
      setpaymentmode({mode:2})
    }
  }
  
  const codvalueChange=(e)=>{
      
    setcodvalue({...codvalue,cod_amount:e.target.value , err:{}})
  }
  const next_step = () => {
    // // console.log("order_id")
   
    let items = [...rowstate];
      let flag = true;
      items.map((val)=>{
       // // // console.log("val=> ",val);
       val.error = {};
        if(val.weight == "" || val.weight <= 0) {
          val.error["weight"] = true;     
          flag = false;   
        }
        if(val.height == "" || val.height <= 0) {
          val.error["height"] = true;     
          flag = false;     
        }
        if(val.width == "" || val.width <= 0) {
          val.error["width"] = true;    
          flag = false;      
        }
        if(val.length == "" || val.length <= 0) {
          val.error["length"] = true;    
          flag = false;      
        }
        // // console.log("val=> ",val);
      })
      setRowState(items)

      let ship_items = [...shipstate];
      let fl = true;
      ship_items.map((val)=>{
       // // // console.log("val=> ",val);
       val.error = {};
        if(val.total_value == "") {
          val.error["total_value"] = true;     
          fl = false;   
        }
        if(val.shipment_content == "") {
          val.error["shipment_content"] = true;     
          fl = false;     
        }
        if(val.quantity == "") {
          val.error["quantity"] = true;    
          fl = false;      
        }
      
        // // console.log("val=> ",val);
      })
      setShipstate(ship_items)
  
    if(state.parcel_type!=""){
    if (flag && fl ) {
       if(paymentmode.mode==1 || paymentmode.mode==2 && codvalue.cod_amount!="" ){
        let dataToSend = { sender_name: parsed.sender_name,
                            sender_mobile: parsed.sender_mobile,
                            sender_email: parsed.sender_email,
                            sender_address: parsed.sender_address,
                            sender_city: parsed.sender_city,
                            sender_city_id: parsed.sender_city_id,
                            sender_country_name: parsed.sender_country,
                            sender_country_code: parsed.sender_country_code,
                            sender_postal_code: parsed.sender_postal_code,
                            receiver_name: parsed.receiver_name,
                            receiver_mobile: parsed.receiver_mobile,
                            receiver_email: parsed.receiver_email,
                            receiver_address: parsed.receiver_address,
                            receiver_city: parsed.receiver_city,
                            receiver_country_name: parsed.receiver_country,
                            receiver_country_code: parsed.receiver_country_code,
                            receiver_postal_code: parsed.receiver_postal_code,
                            sender_state_name:parsed.sender_state_name,
                            receiver_state_name:parsed.receiver_state_name,
                            return_name: parsed.return_name,
                            return_mobile: parsed.return_mobile,
                            return_email: parsed.return_email,
                            return_address: parsed.return_address,
                            return_city: parsed.return_city,
                            return_state_name: parsed.return_state_name,
                            return_country_name: parsed.return_country,
                            return_country_code: parsed.return_country_code,
                            return_postal_code: parsed.return_postal_code,
                            payment_mode:paymentmode.mode==1?"prepaid":"cod",
                            shipment_type:state.parcel_type,
                            cod_amount : codvalue.cod_amount,
                            weight: "", length: "", height: "",
                            width: "", item_name:"",item_value:"",
                            insurance_amount: insurancestate.insurance,
                            type:state.parcel_type,
                            address_id : parsed.sender_address_id,
                            order_id:state.order_number,
                            order_date:state.order_date,
                            mode:parsed.mode,
                            }
              
                            dataToSend.item_name = shipstate.map((sub)=>(
                            sub.shipment_content
                            ))   
                            dataToSend.item_value = shipstate.map((sub)=>(

                            sub.total_value
                            ))      
                            dataToSend.quantity = shipstate.map((sub)=>(

                            sub.quantity
                            ))                   
                                        
                                        
                            dataToSend.weight = rowstate.map((sub) => (
                            sub.weight
                            ))
                            dataToSend.length = rowstate.map((sub) => (
                            sub.length
                            ))
                            dataToSend.height = rowstate.map((sub) => (
                            sub.height
                            ))
                            dataToSend.width = rowstate.map((sub) => (
                            sub.width
                            ))
                            dataToSend.customer_id = userData.customer_id;
                            // // console.log("datatoSend", dataToSend)

                            let url = config.apiUrl + '/shipment/order_create';

                            // setOtherState({ ...otherState, submit_button: false })
                            axios.post(url, dataToSend, { headers: config.headers })
                            .then((responseJson) => {
                            // // // console.log("responseJson => ", responseJson.data.output);
                            // setOtherState({ ...otherState, submit_button: true })
                            if (responseJson.data.status == true) {
                                navigate({pathname:`/package_list`,
                                search: `sender_name=${parsed.sender_name}&sender_address=${parsed.sender_address}&sender_country=${parsed.sender_country}&sender_country_code=${parsed.sender_country_code}&sender_postal_code=${parsed.sender_postal_code}&sender_state_name=${parsed.sender_state_name}&sender_city=${parsed.sender_city}&sender_address_id=${parsed.sender_address_id}&sender_city_id=${parsed.sender_city_id}&sender_mobile=${parsed.sender_mobile}&sender_email=${parsed.sender_email}&receiver_name=${parsed.receiver_name}&receiver_email=${parsed.receiver_email}&receiver_mobile=${parsed.receiver_mobile}&receiver_address=${parsed.receiver_address}&receiver_country=${parsed.receiver_country}&receiver_country_code=${parsed.receiver_country_code}&receiver_postal_code=${parsed.receiver_postal_code}&receiver_city=${parsed.receiver_city}&receiver_state_name=${parsed.receiver_state_name}&return_name=${parsed.return_name}&return_email=${parsed.return_email}&return_mobile=${parsed.return_mobile}&return_address=${parsed.return_address}&return_country=${parsed.return_country}&return_country_code=${parsed.return_country_code}&return_postal_code=${parsed.return_postal_code}&return_city=${parsed.return_city}&return_state_name=${parsed.return_state_name}&mode=${parsed.mode}${shipstate.map((sub)=>(`&items=${sub.total_value},${sub.shipment_content},${sub.quantity}`))}&shipment_type=${state.parcel_type}&insurance=${insurancestate.insurance}&order_id=${state.order_number}&order_date=${state.order_date}${rowstate.map((sub)=>(
                                 `&dimensions=${sub.height},${sub.width},${sub.length},${sub.weight}`
                                 ))}&cod_amount=${codvalue.cod_amount}&payment_mode=${paymentmode.mode==1?"prepaid":"cod"}`
                              })   
                              const Toast = Swal.mixin({
                                toast: true,
                                position: 'bottom-end',
                                showConfirmButton: false,
                                timer: 3000
                              })       
                            Toast.fire({
                            background:"rgb(25, 135, 84)",
                            type: 'unsuccess',
                            title: "Order created successfully.",
                            color:"white"
                            });

                            }
                            })
         
     
       
       
       }
       else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"rgb(231, 91, 75)",
          type: 'unsuccess',
          title: "Please enter cod value",
          color:"white"
        });
       }
   
      
 
    }
    else{
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        background:"rgb(231, 91, 75)",
        type: 'unsuccess',
        title: "Please fill all the Details",
        color:"white"
      });
      // // console.log("----error:   ");      
    }
}else{
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        background:"rgb(231, 91, 75)",
        type: 'unsuccess',
        title: "Please Select Shipment Type",
        color:"white"
      });
      // // console.log("----error:   "); 
}

  
}
  return (
    <div>
      <section class="mb-3 mt-3">
  <div class="container">
    {/* <?php include 'shipment_steps.php';?> */}
    <Shipment_steps value={2}/>
    <div class="row">
      <div class="col-12 col-md">
        <section>
          <div class="row">
          <div class="col-12 col-md mb-5">
          <div class="card border-0 shadow-sm bg-light">
            <div class="card-body">
          <h4 class="mb-3">Shipment Details</h4>
              <section class="mb-3">
                <div class="row mt-3">
                    <div class="col-12 col-md-4 mb-3">
                      <label for="email">Order ID (Auto Generated)</label>
                      <input type="text" class="form-control" id="text" placeholder="" value ={state.order_number} name="order_number" onChange={(e)=>handleChange(e)} />
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                      <label for="email">Order Date</label>
                      <input type="date" class="form-control" id="text" placeholder="Order date" name="order_date" onChange={(e)=>handleChange(e)} />
                    </div>
                </div>
              </section>
              <hr/>
              <section class="mb-3 py-3">
                <div class="row">
                    <div class="col-12 col-md-4">
                      <h6 class="">Shipment Type</h6>
                      <div class="row mb-3">
                          <div class="col-12 col-md-12 mb-3">
                            <div>
                              <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio"  onChange={(e)=>handleChange(e)} name="document" id="inlxineRadio1" value="Document"  checked={documenttype.document==true && parceltype.parcel==false}/>
                                <label class="form-check-label" for="inlineRadio1">Document</label>
                              </div>
                              <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="parcel" onChange={(e)=>handleChange(e)} id="inlixneRadio2" value="Parcel" checked={parceltype.parcel==true && documenttype.document==false }/>
                                <label class="form-check-label" for="inlineRadio2">Parcel </label>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>

                <h6 class="">Shipment Dimensions</h6>
                {rowstate.map((sub, index) => (
                <div class="row mb-3">
                    <div class="col-12 col-md">
                      <label for="email" class="form-label">Height ({dimensionState.companyDetail.map((subscriber)=>( 
                         
                         subscriber.set_dimension ))})</label>
                      <div class="input-group">
                        <span class="input-group-text">H</span>
                        <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter height" name="height" onChange={(e) => handleChange2(e, index)} value={sub.height} />
                      </div>
                      {(sub.error.height)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Height is required</small></>:""}
                  </div>
                  <div class="col-12 col-md">
                      <label for="email" class="form-label">Width ({dimensionState.companyDetail.map((subscriber)=>( 
                         
                         subscriber.set_dimension ))})</label>
                      <div class="input-group">
                        <span class="input-group-text">W</span>
                        <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter width" name="width" onChange={(e) => handleChange2(e, index)}  value={sub.width} />
                      </div>
                      {(sub.error.width)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Width is required</small></>:""}
                  </div>
                  <div class="col-12 col-md">
                      <label for="email" class="form-label">Length ({dimensionState.companyDetail.map((subscriber)=>( 
                         
                         subscriber.set_dimension ))})</label>
                      <div class="input-group">
                        <span class="input-group-text">L</span>
                        <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter length" name="length" onChange={(e) => handleChange2(e, index)}  value={sub.length} />
                      </div>
                      {(sub.error.length)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Length is required</small></>:""}
                  </div>
                  <div class="col-12 col-md">
                      <label for="email" class="form-label">Weight ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</label>
                      <div class="input-group">
                        <span class="input-group-text">W</span>
                        <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter weight" name="weight" onChange={(e) => handleChange2(e, index)}  value={sub.weight} />
                      </div>
                      {(sub.error.weight)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Weight is required</small></>:""}
                      
                  </div>
                  <div class="col-12 col-md-1">
                  {(sub.buttonType == "add") ?
                            <span class=""><i class="material-icons mt-4" onClick={() => addRows(1)} style={{ cursor: "pointer", color: "red" }}>add_circle_outline</i></span> :
                            <span class=""><i class="material-icons mt-4 " onClick={() => addRows(2, index)} style={{ cursor: "pointer", color: "red" }}>remove_circle_outline</i></span>
                          }
                  </div>
                </div>
                ))}
              </section>
            </div>
          </div>
        </div>

            
            
            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm mb-3 bg-light">
                <div class="card-body">
                  <h4 class="pt-3 pb-3">Shipment Details</h4>
                  {shipstate.map((sub,index)=>(
                  <div class="row ">
                      <div class="col-12 col-md">
                        <section class="mb-3">
                          <h6>Item {index +1 }:</h6>
                          <div class="row">
                            <div class="col-12 col-md mb-2">
                              <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="Enter email" name="shipment_content" onChange={(e) => handleChange3(e,index)}/>
                                <label for="email">Shipment content (eg. mobile, laptop) <span class="text-danger">*</span></label>
                              </div>
                              {(sub.error.shipment_content)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Shipment content is required</small></>:""}
                            </div>
                            <div class="col-12 col-md-2 mb-2">
                              <div class="form-floating">
                              <input type="number" class="form-control" id="email" placeholder="Enter email" name="quantity" onChange={(e) => handleChange3(e,index)}/>
                                <label for="email">Quantity <span class="text-danger">*</span></label>
                              </div>
                              {(sub.error.quantity)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Quantity is required</small></>:""}
                            </div>
                            <div class="col-12 col-md-3 mb-2">
                              <div class="form-floating">
                              <input type="number" class="form-control" id="email" placeholder="Enter email" name="total_value" onChange={(e) => handleChange3(e,index)}/>
                                <label for="number">Item Value<span class="text-danger">*</span></label>
                              </div>
                              {(sub.error.total_value)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Item Value is required</small></>:""}
                            </div>
                          </div>
                        </section>
                       
                      </div>
                  </div>
                  ))}
                </div>
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm mb-3 bg-light">
                <div class="card-body">
                  <h4 class="pt-3 pb-3">Shipment Mode</h4>
                  <div class="row">
                      <div class="col-12 col-md">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="prepaid" id="inlineRadio1" value="option1" onChange={(e) => payment_mode(e)} checked={paymentmode.mode==1} />
                          <label class="form-check-label" for="inlineRadio1">Prepaid</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="cod" id="inlineRadio2" value="option2" onChange={(e) => payment_mode(e)}  checked={paymentmode.mode==2} />
                          <label class="form-check-label" for="inlineRadio2">COD</label>
                        </div>
                        {paymentmode.mode==2?
                        <>
                        <div class="form-floating">
                                <input type="text" class="form-control" id="email" name="cod_amount" onChange={(e)=>codvalueChange(e)}/>
                                <label for="number">COD Value<span class="text-danger">*</span></label>
                               
                        </div>
                         {/* {codvalue.err.cod_error?
                          <>
                          <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                          lineHeight: "1.66667",
                          fontWeight: "400",
                          letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Cod Value less than {codlimit.limit}</small></>:""} */}
                        
                        </>   :""}
                           
                      </div>
                      <div class="col-12 col-md">
                        Insurance (Protect your shipment by adding insurance)
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input"  type="checkbox" name="insurance" defaultChecked={insurancestate.insurance} onChange={(e) => handleChange4(e)}/>
                               <label class="form-check-label" for="flexSwitchCheckChecked">Yes / No</label>
                            </div>
                      </div>
                  </div>



                  
                </div>
              </div>
            </div>
                 
            <div class="row  mt-4">
                <div class="col-12 col-md">
                <a href="/ship_1" class="btn btn-light btn-lg"><span class="mt-5"><i class="material-icons-outlined">west</i></span> Previous Step </a>
                </div>
                <div class="col-12 col-md text-end">
                <a onClick={()=>next_step()} class="btn btn-main btn-lg">Create Order To move on Next Step <span class="mt-5"><i class="material-icons-outlined">east</i></span></a>
                </div>
            </div>
          </div>
        </section>
      </div>
      <div class="col-md-4 bg-light mb_display_none">
        <section class="ps-4 pt-3">
          <h3 class="fs-5 pb-3">Basic information</h3>
          <div class="row">
            <div class="col-md-6">
              <small class="text-muted">Pickup</small>
              <h4 class="fs-6">{parsed.sender_address}, {parsed.sender_state_name}, {parsed.sender_city} - {parsed.sender_postal_code} </h4>
              <p>{parsed.sender_country}</p>
            </div>
            <div class="col-md-6">
              <small class="text-muted">Delivery</small>
              <h4 class="fs-6">{parsed.receiver_address}, {parsed.receiver_state_name}, {parsed.receiver_city} - {parsed.receiver_postal_code}</h4>
              <p>{parsed.receiver_country}</p>
            </div>
            {/* <div class="col-md-12">
              <small class="text-muted">Item(s)</small>
              <h4 class="fs-5">1</h4>
            </div> */}
          </div>
        </section>

        <hr class="m-4" />

        {/* <section class="ps-4">
          <h3 class="fs-5 pb-3">Shipping Items</h3>
          <div class="row">
            <div class="col-md-12">
              <small class="text-muted">2 KG - 12x13x28 cm</small>
            </div>
          </div>
        </section> */}

        {/* <hr class="m-4" /> */}


        <section class="ps-4">
          <h3 class="fs-5 pb-3">Order details</h3>
          <div class="row">
            <div class="col-md-6">
              <small class="text-muted">Mode</small>
              <h4 class="fs-5">{parsed.mode}</h4>
            </div>
            {/* <div class="col-md-6">
              <small class="text-muted">Type</small>
              <h4 class="fs-5">Parcel</h4>
            </div>
            <div class="col-md-6">
              <small class="text-muted">Total Weight</small>
              <h4 class="fs-5">1KG</h4>
            </div> */}
          </div>
        </section>
        <hr class="m-4" />

        {/* <section class="ps-4">
          <h3 class="fs-5 pb-3">Pricing details</h3>
          <div class="row">
            <div class="col-md-6">
              <small class="text-muted">Sub total Item(s)</small>
            </div>
            <div class="col-md-6">
              <h4 class="fs-6">$25.99</h4>
            </div>
          </div>
        </section> */}
        {/* <section class="ps-4">
          <div class="row pt-4">
            <div class="col-md-6">
              <h4 class="fs-4">Total Item(s) Value</h4>
            </div>
            <div class="col-md-6">
              <h4 class="fs-4">$25.99</h4>
            </div>
          </div>
        </section> */}

      </div>
    </div>      
  </div>
</section>
    </div>
  )
}

export default Ship_2
