import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { CornerUpLeft, Plus } from 'lucide-react'

const Create_order_new = () => {
  return (
    <div>
        <Left_menu value={2}/>
      
      <div class="home-section">
         
          <Header/>
              <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                             <CornerUpLeft/>
                            </a>
                        </div>
                        <div class="">
                            <h2>Create Orders</h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-end btns">
                    
                </div>
            </div>
              <section class="create_order_adv">
                <div class="row">
                    <div class="col-12 col-md">
                      <div class="card ">
                        <div class="card-body">
                          <div class="row">
                              <div class="col-12 col-md-3">
                              <h6>Shipping from</h6>
                              </div>
                              <div class="col-12 col-md">
                                <select class="form-select">
                                  <option>Mumbai Branch 209, Shop Opp 2 Ashok anil Multiplex,Kalyan Ambernath - 400001</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                </select>
                              </div>
                              <div class="col-12 col-md-1">
                                <a href="" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Direct Sales" data-bs-original-title="Add new warehouse"><Plus/></a>
                              </div>
                          </div>
                        </div>
                      </div>
                      <h4 class="pb-1">Add Buyer's Details</h4>
                      <div class="card">
                        <div class="card-body">
                          <section class="pb-5">
                              <h6 class="">To whom is the order being delivered? <span class="text-muted">(Buyer's Info)</span></h6>
                              <div class="row">
                                  <div class="col-12 col-md-4 mb-3">
                                      <label for="" class="form-label">Mobile Number:</label>
                                      <div class="input-group">
                                          <span class="input-group-text"><i class="fa fa-mobile" aria-hidden="true"></i></span>
                                          <input type="text" class="form-control" placeholder="Enter Buyer's Mobile Number"/>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-4 mb-3">
                                      <label for="" class="form-label">Full Name:</label>
                                      <input type="text" class="form-control" id="" placeholder="Enter Full Name" name=""/>
                                  </div>
                                  <div class="col-12 col-md-4 mb-3">
                                      <label for="" class="form-label">Email Id:</label>
                                      <input type="text" class="form-control" id="" placeholder="Enter Email Id" name=""/>
                                  </div>
                              </div>
                          </section>

                          <section>
                              <h6 class="">Where is the order being delivered to? <span class="text-muted">(Buyer's Address)</span></h6>
                              <div class="row">
                                  <div class="col-12 col-md-12 mb-3">
                                      <label for="" class="form-label">Complete Address:</label>
                                      <input type="text" class="form-control" id="" placeholder="Enter Complete Address" name=""/>
                                  </div>
                                  <div class="col-12 col-md-4 mb-3">
                                      <label for="" class="form-label">Pincode:</label>
                                      <input type="text" class="form-control" id="" placeholder="Enter Pincode" name=""/>
                                  </div>
                                  <div class="col-12 col-md-4 mb-3">
                                      <label for="" class="form-label">State:</label>
                                      <select class="form-select form-select-sm">
                                        <option>Select</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                      </select>
                                  </div>
                                  <div class="col-12 col-md-4 mb-3">
                                      <label for="" class="form-label">City:</label>
                                      <select class="form-select form-select-sm">
                                        <option>Select</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                      </select>
                                  </div>
                              </div>
                          </section>
                          <div class="py-3">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" checked=""/>
                                <label class="form-check-label" for="inlineCheckbox1">Billing Address is same is Shipping Address</label>
                              </div>
                          </div>
                        </div>
                      </div>
                      <h4>Order Details</h4>
                      <div class="card border-0 ">
                        <div class="card-body">
                          <section class="pb-3">
                            <h6 class="mb-1">Payment Details</h6>
                            <small class="">Select mode of payment that your buyer has chosen for the order</small>
                            <div class="row mt-4">
                                <div class="col-12 col-md-3 mb-3">
                                    <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked=""/>
                                      <label class="form-check-label" for="inlineRadio1">Prepaid <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Payment already received from the Buyer" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md mb-3">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                    <label class="form-check-label" for="inlineRadio1">COD (Cash On Delivery) <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="COD payment will be credited to your account" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></label>
                                  </div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-12 col-md-5 mb-3">
                                <input type="text" class="form-control" id="" placeholder="Enter COD amount" name=""/>
                              </div>
                            </div>
                          </section>
                          <hr/>
                          <section class="pb-3">
                              <h6 class="">Product Details</h6>
                              <div class="row g-1">
                                  <div class="col-12 col-md-1 mb-3">
                                      <label for="" class="form-label">SKU:</label>
                                      <input type="text" class="form-control" id="" placeholder="SKU" name=""/>
                                  </div>
                                  <div class="col-12 col-md-4 mb-3">
                                      <label for="" class="form-label">Product 1 Name:</label>
                                      <input type="text" class="form-control" id="" placeholder="Enter your product name" name=""/>
                                  </div>
                                  <div class="col-12 col-md-3 mb-3">
                                    <label for="" class="form-label">Product Category:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Category" name=""/>
                                  </div>
                                  <div class="col-12 col-md-2 mb-3">
                                    <label for="" class="form-label">Unit Price:</label>
                                      <div class="input-group">
                                          <span class="input-group-text">₹</span>
                                          <input type="number" class="form-control " placeholder="Enter Price" value="0.00"/>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-1 mb-3">
                                      <label for="" class="form-label">Quantity:</label>
                                      <input type="number" class="form-control" id="" placeholder="Enter quantity" name="" value="1"/>
                                  </div>
                                  <div class="col-12 col-md-1 mb-3">
                                    <div class=" add_btns">
                                      <a href="#" class="btn btn-primary btn-xs"><i class="material-icons-outlined">add</i></a>
                                    </div>
                                  </div>
                              </div>
                          </section>
                          <hr/>
                          <div class="card border-0 bg-light">
                            <div class="card-body">
                              <div class="d-flex justify-content-between pb-2">
                                  <div class="">
                                    Sub-total for Product
                                  </div>
                                  <div class="">Rs. 0</div>
                              </div>
                              <div class="d-flex justify-content-between pb-4">
                                <div class="">
                                  Other Charges
                                </div>
                                <div class="">Rs. 0</div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="">
                                  <h5>Total Order Value</h5>
                                </div>
                                <div class=""><h5>Rs. 0</h5></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <section class="pb-3">
                            <div class="row">
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="" class="form-label">Order ID: <small class="text-muted">(Leave Black for Auto Generate)</small></label>
                                    <input type="text" class="form-control" id="" placeholder="Order ID" name=""/>
                                </div>
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="" class="form-label">Order Date: <small class="text-muted">(Leave Black for Auto Generate)</small></label>
                                    <input type="date" class="form-control" id="" placeholder="Enter Full Name" name=""/>
                                </div>
                                <a data-bs-toggle="collapse" data-bs-target="#show_smgt" class="text-primary">Show advance options</a>

                                <div id="show_smgt" class="collapse">
                                  <div class="col-12 col-md-12 mb-3">
                                    <label for="" class="form-label">Sales Channel:</label>
                                    <select class="form-select form-select-sm">
                                      <option>Direct Sales</option>
                                      <option>Shopify</option>
                                      <option>Woocommerce</option>
                                      <option>Flipkart</option>
                                      <option>Other</option>
                                    </select>
                                  </div>
                                  <div class="col-12 col-md-12 mb-3">
                                      <label for="" class="form-label">Seller Note:</label>
                                      <textarea class="form-control" rows="2" id="comment" name="text" placeholder="Enter details"></textarea>
                                  </div>
                                </div>
                            </div>
                        </section>
                        </div>
                      </div>
                      <div class="card border-0 ">
                        <div class="card-body">
                          <section class="pb-3">
                              <h6 class="">Box Type</h6>
                              <div class="row">
                                  <div class="col-12 col-md-12 mb-3">
                                      <select class="form-select form-select-sm">
                                        <option>Custom Box</option>
                                        <option>Small Box 1 (10 x 10 x 10 cm) - 10kg</option>
                                        <option>3</option>
                                        <option>4</option>
                                      </select>
                                  </div>
                              </div>
                          </section>
                          <section class="pb-3">
                              <h6 class="">Dead Weight <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Dead Weight is the physical weight" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></h6>
                              <div class="row">
                                  <div class="col-12 col-md-12 mb-3">
                                      <div class="input-group">
                                        <input type="number" class="form-control" placeholder="Your shpiment weight" value="0.50"/>
                                        <span class="input-group-text">KG</span>
                                      </div>
                                      <small class="text-muted">
                                        (Max. 3 digits after decimal place) <br/> Note: The minimum chargeable weight is 0.50 Kg
                                      </small>
                                  </div>
                              </div>
                          </section>
                          <hr/>
                          <section class="pb-3">
                            <h6 class="">Volumetric Weight <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Volumetric Weight is calcuated by LxHxW/Range" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></h6>
                            <small>Enter packages dimensions to calculate Volumetric Weight</small>
                            <div class="row pt-3">
                                <div class="col-12 col-md-12 mb-3">
                                    <div class="row g-1">
                                        <div class="col-12 col-md-4 mb-3">
                                          <label for="" class="form-label">Height:</label>
                                          <div class="input-group">
                                            <input type="number" class="form-control" placeholder="Your shpiment weight" value="0"/>
                                            <span class="input-group-text">CM</span>
                                          </div>
                                        </div>
                                        <div class="col-12 col-md-4 mb-3">
                                          <label for="" class="form-label">Width:</label>
                                          <div class="input-group">
                                            <input type="number" class="form-control" placeholder="Your shpiment weight" value="0"/>
                                            <span class="input-group-text">CM</span>
                                          </div>
                                        </div>
                                        <div class="col-12 col-md-4 mb-3">
                                          <label for="" class="form-label">Length:</label>
                                          <div class="input-group">
                                            <input type="number" class="form-control" placeholder="Your shpiment weight" value="0"/>
                                            <span class="input-group-text">CM</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                  
                                </div>
                            </div>
                            <div class="card border-0 bg-light">
                              <div class="card-body">
                                <h6>Volumetric Weight <span class="ms-5">0.00 Kg</span></h6>
                              </div>
                            </div>
                          </section>
                          <hr/>
                          <section>
                            <div class="card border-0 bg-light py-3">
                              <div class="card-body">
                                <h5 class="mb-3">Applicable Weight <span class="ms-5">0.00 Kg</span></h5>
                                <small class="text-muted">*Applicable weight is the heavier among the two weights that is Dead Weight V/s the Volumetric Weight, basis on which freight charges are calculated.<br/>
                                  *Final chargeable weight will be based on the weight slab of the courier selected before shipping</small>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                      <div class="d-grid">
                        <a href="" class="btn btn-primary btn-block">Create Order</a>
                      </div>
                    </div>
                </div>
                
                
                
                
              </section>
            </div>
        
    </div>
  )
}

export default Create_order_new
