import React from 'react'
import Header from './Header'
import Left_menu from './Left_menu'

const Allocation = () => {
  return (
    <div>
     <Left_menu value={10}/>
    <section class="home ">
        {/* <?php include 'header.php';?>  */}
<Header/>
        

        <section class="mb-3 pt-4">
          <div class="container">
            <div class="row mb-3">
              <div class="col-12 col-md">
                <h3 class="pt-3">Order Allocation Engine</h3>
                <nav aria-label="breadcrumb" class="">
                    <ol class="breadcrumb  ">
                      <li class="breadcrumb-item"><a href="./">Home</a></li>
                      <li class="breadcrumb-item " aria-current="page"><a href="tools.php">Tools</a></li>
                    </ol>
                </nav>
              </div>
              
              <div class="col-12 col-md-4 text-end">
                <a href="/customer/allocation_rule" class="btn btn-primary btn-sm "><i class="fa fa-plus" aria-hidden="true"></i> Create New Shipping Rule</a>
              </div>
            </div>      
          </div>
        </section>


        <section class=" container mb-3">
          <div class=" allocation_bx_head">
            <div class="row justify-content-center py-3">
              <div class="col-12 col-md-6">
                <p class="">Please ensure that your search prioritization involves focusing primarily on identifying and selecting couriers.</p>
              </div>
            </div>
            
            <div class="row justify-content-center ">
              <div class="col-12 col-md-2">
                <div class="card ">
                  <div class="card-body">
                    <div class="icon_allo">
                      <img src="../../../img/loss.png" class="img-fluid" />
                    </div>
                    <h5>Cheapest</h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2">
                <div class="card active">
                  <div class="card-body">
                    <div class="icon_allo">
                      <img src="../../../img/custom.png" class="img-fluid" />
                    </div>
                    <h5>Custom</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <section>
            <div class="row justify-content-center py-3">
              <div class="col-12 col-md-10">
                <div class="card my-3 text-center border-0">
                  <div class="card-body">
                    <small>Drag and drop the couriers to create a customized priority based on your shipping needs.</small>
                  </div>
                </div>

                 <div class="allocation_bx_content">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="row justify-content-center">
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/Delhivery.png" class="img-fluid" />
                              </figure>
                              <h6>Delhivery Surface</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/ExpressBees-Surface.png" class="img-fluid" />
                              </figure>
                              <h6>Delhivery Surface</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/Aramex.png" class="img-fluid" />
                              </figure>
                              <h6>Delhivery Surface</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/Dtdc.png" class="img-fluid" />
                              </figure>
                              <h6>Delhivery Surface</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div> 


                <div class="allocation_bx_content">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="row justify-content-center" ondrop="drop(event)" ondragover="allowDrop(event)">
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder" draggable="true" ondragstart="drag(event)" id="drag1">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/Delhivery.png" class="img-fluid" />
                              </figure>
                              <h6>Delhivery Surface</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder" draggable="true" ondragstart="drag(event)" id="drag2">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/ExpressBees-Surface.png" class="img-fluid" />
                              </figure>
                              <h6>ExpressBees Surface</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder" draggable="true" ondragstart="drag(event)" id="drag3">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/Aramex.png" class="img-fluid" />
                              </figure>
                              <h6>Aramex</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                          <div class="col-12 col-md-3 mb-3">
                            <div class="box_holder" draggable="true" ondragstart="drag(event)" id="drag4">
                              <figure>
                                <img src="https://app.shiprocket.in/app/img/couriers/Dtdc.png" class="img-fluid" />
                              </figure>
                              <h6>DTDC</h6>
                              <small>Min. Weight: 0.50KG</small>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </section>
          


          <div class="allocation_bx_footer">
            <div class="row justify-content-center py-3">
              <div class="col-12 col-md-4">
                <div class="d-grid">
                  <a href="" class="btn btn-primary btn-block">Save Settings</a>
                </div>
              </div>
            </div>
          </div>
          
        </section>


        
    </section> 
    </div>
  )
}

export default Allocation
