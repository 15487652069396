import React from 'react'
import Customer_header from './Customer_header'
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Footer from './Footer'
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../config"
import Moment from 'react-moment';
import Header_nav from './Header_nav';
import download from 'downloadjs'
import Header from './Customer/Header';
import Left_menu from './Customer/Left_menu';


const Summary = () => {
  let navigate = useNavigate()
  const { record_id } = useParams()
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })
  const [state, setState] = React.useState({ log: [] })
  const [stateRecord, setStateRecord] = React.useState({ total_record: 0, uploaded_record: 0, status: "" })
  // console.log("state === " , state)
  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
  const [shipSelect, setShipSelect] = React.useState({ shipment_id: "", cancel_reason: "" });
  const [modalState, setModalState] = React.useState({ show: false });
  const [modaldtdtcState, setModaldtdcState] = React.useState({ show: false });
  const [dtdc, setdtdc] = React.useState();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000
  })

  // console.log("shipmentState",shipmentstate)
  React.useEffect(() => {

    shipment_list()

    // dimension_detail()

  }, [])

  const shipment_list = (status) => {
    let dataToSend = {
      customer_id: userData.customer_id, record_id: record_id
    };

    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/view_summary';

    // // console.log("headers => ", config.headers);


    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJson11 => ", res);
        if (res.data.output.length > 0) {
          // let status = stateRecord.status;
          setState(res.data.output[0])
          setStateRecord({ uploaded_record: res.data.output[0].uploaded_record, total_record: res.data.output[0].total_record, status: "" })
          // console.log(status)
          if (res.data.output[0].uploaded_record != res.data.output[0].total_record) {
            summary_record()
          }
        }

      })
      .catch((error) => {
        //Hide Loader
        //   setLoadingstate({...loadingstate,loading:false})
        // console.log(error);
      });

  }

  const summary_record = () => {
    console.log("summary_record called")
    let dataToSend = {
      customer_id: userData.customer_id, record_id: record_id
    };

    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/view_summary_upload_record?customer_id=' + userData.customer_id + '&record_id=' + record_id + '';

    // // console.log("headers => ", config.headers);
    axios({
      method: "get",
      url: url,
      headers: config.headers,
      responseType: "stream",
      onDownloadProgress: progressEvent => {
        // console.log("progressEvent ", progressEvent.currentTarget.response);
        let parseData = progressEvent.currentTarget.response;
        parseData = parseData.split("Z");
        parseData = JSON.parse(parseData[parseData.length - 1]);

        // console.log(parseData)
        // console.log(state);
        setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })


      }
    }).then((res) => {
      //  console.log("responseJson11 => ", res);
      // setState(res.data.output)

    })
      .catch((error) => {
        //Hide Loader
        //   setLoadingstate({...loadingstate,loading:false})
        // console.log(error);
      });

  }

  const handleRefreshButtonClick = () => {
    window.location.reload(); // Reloads the page
  };

  return (
    <div>
   <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
            <Header/>
        <div class="">

          <div class="card mb-3 mt-4">
            <div class="card-body">
              <header class="">
                <div class="row mb-4 mt-3">
                  <div class="col col-md-6">
                    <div class="d-flex">

                      <div className=''>
                        <h4><a href="/customer/orders"><i class="material-icons">chevron_left</i></a>Summary</h4>
                      </div>
                    </div>


                  </div>
                  <div className='col-md-6 col-12 text-end'>    <h4>Uploaded Records </h4>
                    <small>{stateRecord.uploaded_record}/{stateRecord.total_record}</small><br />
                    {(stateRecord.status == "pending") ? <small>Uploading in progress</small> : (stateRecord.status == "finished") ? <small>Uploading Finished</small> : ""}
                  </div>

                </div>

              </header>
            </div>
          </div>



          <div class="card ">
            <div class="card-body">


              <table class="table  shipment_box table-responsive">
                <thead>
                  <tr class="custom_row_mb">
                    <th>order_id</th>
                    <th> Failed Reason</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>


                  {state.log.length > 0 ?
                    state.log.map((item) => (
                      <tr class="custom_row_mb">
                        <td>
                          {item.order_id}
                        </td>

                        <td>
                          {item.reason}

                        </td>
                        <td>
                          {item.status == 2 ?
                            <span className='badge bg-danger'>Failed</span> :
                            <span className='badge bg-success'>Success</span>}

                        </td>




                      </tr>
                    )) : <>


                      <tr >
                        <td colSpan="3" style={{ textAlign: 'center' }} >
                          {/* Centered content */}
                          <div className='mt-5'>
                            <h6>Your Orders Are Still In Processing</h6><br />
                            <small>Records will appear after Importing</small>
                            <br />
                            <br />
                            <button class="btn btn-primary btn-block" onClick={() => handleRefreshButtonClick()}>Refresh</button>
                          </div>
                        </td>
                      </tr>


                    </>

                  }




                </tbody>
              </table>
            </div>
          </div>


        </div>
    </div>
    </div>
  )
}

export default Summary
