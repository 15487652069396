import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import Swal from 'sweetalert2'
import config from "../config"
import axios from 'axios';
import Select from 'react-select';
import { useRef } from 'react';
import Footer from './Footer'
import Header_nav from './Header_nav'

const Demo_page = (props) => {
  const [selectedItem, setSelectedItem] = useState(0);
  // console.log("prev",selectedItem)
  const scrollRef = useRef(null);
  if(props.value!=undefined){
    // console.log("data.....<<<<-----===",props.value.country_name)
   }
     const location = useLocation();
     // console.log("location",location)
     let navigate = useNavigate();
     const [country, setCountryState] = React.useState([])
     const [rstate, setRState] = React.useState(
       []
     );
     const [load, setload] = React.useState(true)
     const [statee, setStatee] = React.useState({
       selectedOption: null
     });
     const [postalcode, setPostalCode] = React.useState([]);
     // // console.log("postalcode",postalcode)
     const [state, setState] = React.useState({ from_country: "", from_country_type: "", from_country_code: "", from_postal_code: "", from_city: "", to_country: "", to_country_type:"",to_country_name: "", to_country_code: "", to_postal_code: "", to_city: "", from_country_name: "",error:{} })
     // console.log("state",state)
     const [rowstate, setRowState] = React.useState([{ weight: "", height: "", width: "", length: "", buttonType: "add",error:{} }])
     //// console.log("rowstate", rowstate)
     const [isopen, setOpen] = React.useState(false)
     const [isopen1, setOpen1] = React.useState(false)
   
     const [isloading1, setLoading1] = React.useState(true)
     const [isloading, setLoading] = React.useState(true)
   
     const [ismodal, setModal] = React.useState(false)
     const [ismodal1, setModal1] = React.useState(false)
     const [countrymodal, setcountrymodal] = React.useState(false)
     const [countrymodal1, setcountrymodal1] = React.useState(false)
     const [active, setActive] = React.useState(0);
     const [focusedIndex, setfocusedIndex] = React.useState(-1);
   
     const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
   
     // const [list, setList] = React.useState(
     //   [{name: 'London', id: 1},
     //    {name: 'usa', id: 2},
     //   ]);
     const Toast = Swal.mixin({
       toast: true,
       position: 'bottom-end',
       showConfirmButton: false,
       timer: 3000
     })
   
   const resultContainer = useRef(null)
   
     const [list, setList] = React.useState(
       [{name: 'London', id: 1},
        {name: 'usa', id: 2},
       ]);
   
   
   
   
     React.useEffect(() => {
   
       get_country_list("")
       dimension_detail()
   
     }, [])
   
     React.useEffect(()=>{
      if(!scrollRef.current) return ; 
      scrollRef.current.scrollIntoView({
       behavior: 'smooth',
       block: 'center',
       inline: 'center',
     })
     },[selectedItem])
 
     React.useEffect(()=>{
       if(props.value!=undefined ){
         setState({...state,to_country_type:props.value.country_name, to_country:props.value.country_id, to_country_code: props.value.country_code, to_country_name: props.value.country_name})
       }
     },[])
 
     const dimension_detail = () =>{
       let full_api = config.apiUrl + `/user/get_company_info_api`;
       let sendData = {};
     
       axios.post(full_api, sendData, { headers: config.headers }).then(res => {
         setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
         // console.log("rnmmmmm", res);
       }).catch((e) => {
         // toast.configure()
          //toast.error("Some thing went wrong")
         // console.log("----error:   ", e);
       })
     }
   
   
   
     // function textChange(e){
   
     //   const value = e.target.value;
     //   const names = e.target.name;
     //   // setState({ ...state, [names]: value })
     //   // console.log("name", value)
     //   // let names = e.target.name;
     //   // let value = e.target.value
     //   // setState({ ...state, from_country: JSON.parse(e.target.value).country_id, from_country_code: JSON.parse(e.target.value).country_code, from_country_name: JSON.parse(e.target.value).country_name })
     // }
   
   
     const handleChange2 = (e, index) => {
        // console.log("eee",e.target.value)
       let items = [...rowstate];
       let names = e.target.name;
       let value = e.target.value;
       if(value > 0) {
         items[index].error[names] = false;
       }else{
         items[index].error[names] = true;
       }
      // // console.log(items[index][names])
       items[index][names] = value;
       setRowState(items)
       //setState({ ...state, [names]: value })
     }
     const ship_now = () => {
       //let values = JSON.stringify(rowstate)
       // rowstate.map((sub)=>{
       //   // console.log("sub",sub)
       //   values = sub.weight
       // })
       //// console.log("rowstate", rowstate)
       let items = [...rowstate];
       let flag = true;
       items.map((val)=>{
        // // console.log("val=> ",val);
        val.error = {};
         if(val.weight == "") {
           val.error["weight"] = true;     
           flag = false;   
         }
         if(val.height == "") {
           val.error["height"] = true;     
           flag = false;     
         }
         if(val.width == "") {
           val.error["width"] = true;    
           flag = false;      
         }
         if(val.length == "") {
           val.error["length"] = true;    
           flag = false;      
         }
         // console.log("val=> ",val);
       })
       setRowState(items)
       let itemsR = {...state};
       itemsR.error = {};
       if (itemsR.from_country == "") {
         itemsR.error["from_country"] = true;   
         
       }if(itemsR.from_postal_code == ""){
         itemsR.error["from_postal_code"] = true; 
          
       } if(itemsR.to_country == ""){
         itemsR.error["to_country"] = true;   
         
       } if(itemsR.to_postal_code == "") {
         itemsR.error["to_postal_code"] = true;   
        
       }
       setState(itemsR)
       // console.log("R =>",itemsR);
       // console.log("R length =>",Object.keys(itemsR.error).length);
       if(Object.keys(itemsR.error).length == 0 && flag){
         // navigate(`/package_list`, { state: { from_country: state.from_country, from_country_name: state.from_country_name, from_country_code: state.from_country_code, from_postal_code: state.from_postal_code, to_country: state.to_country, to_country_name: state.to_country_name, to_country_code: state.to_country_code, to_postal_code: state.to_postal_code, weight: state.weight, height: state.height, width: state.width, length: state.length, from_city: state.from_city, to_city: state.to_city } })
         navigate({
           pathname: '/package_list',
           search: `origin_country_code=${state.from_country_code}&origin_postal_code=${state.from_postal_code}&origin_country_name=${state.from_country_name}&destination_country_code=${state.to_country_code}&destination_postal_code=${state.to_postal_code}&destination_country_name=${state.to_country_name}&origin_city=${state.from_city}&destination_city=${state.to_city} ${rowstate.map((sub) => (
             `&dimensions=${sub.weight},${sub.height},${sub.length},${sub.width}`
           ))}`,
         });
       }
       else {      
         
         Toast.fire({
           background:"rgb(231, 91, 75)",
           type: 'unsuccess',
           title: "Please fill all the Details",
           color:"white"
         });
         // console.log("----error:   ");
       }
   
     }
     function selectcountry(value) {
       // console.log("seeeee----->", value)
       // // console.log("ttttt----->", text)
       setcountrymodal(false)
       let stateSample = {...state};
       stateSample.error.from_country = false;
       // // console.log("e", JSON.parse(e.target.value))
       // let names = e.target.name;
       // let value = e.target.value
       setState({ ...state,from_country_type:value.country_name, from_country: value.country_id, from_country_code: value.country_code, from_country_name: value.country_name,error:stateSample.error })
   
     }
   
     function selectcountry1(value) {
       // console.log("eee----->", value)
       // // console.log("ttttt----->", text)
       setcountrymodal1(false)
       let stateSample = {...state};
       stateSample.error.to_country = false;
       // // console.log("e", JSON.parse(e.target.value))
       // let names = e.target.name;
       // let value = e.target.value
       setState({ ...state,to_country_type:value.country_name, to_country: value.country_id, to_country_code: value.country_code, to_country_name: value.country_name,error:stateSample.error })
   
     }
     const get_country_list = async (value) => {
      // const value = e.target.value;
       setload(true)
       // console.log("value--------.....", value)
      
  
       let url = config.apiUrl + '/country/country_list';
   
       // // console.log("headers => ", config.headers);
   
       let senddata = {}
       if (value) {
         senddata.keyword = value
       }
       axios.post(url, senddata, { headers: config.headers })
         .then((responseJson) => {
           // // console.log("responseJson => ", responseJson.data.output);
           setCountryState(responseJson.data.output)
           setload(false)
         })
         .catch((error) => {
           //Hide Loader
   
           // console.error(error);
         });
     };
   
   
     function onTextChange(e, props) {
 
    
       const value = e.target.value;
       const names = e.target.name;
       setState({ ...state, [names]: value })
       // console.log("value--------.....", value.length)
        if(value.length>1){
       
       setLoading1(true)
       setOpen1(false)
       setModal1(true)
       let senddataa = {
         keyword: value,
         country: ""
       };
       if (names == "from_postal_code") {
         senddataa.country = state.from_country
       }
       else {
         senddataa.country = state.to_country
       }
       // console.log("senddata", senddataa)
       let url = config.apiUrl + '/country/search_city_postcode';
       axios.post(url, senddataa, { headers: config.headers })
         .then((responseJson) => {
           // console.log("responseJson======> ", responseJson.data.output);
           // let req = responseJson.data.output
           setPostalCode(responseJson.data.output)
           setLoading1(false)
   
         })
         .catch((error) => {
           //Hide Loader
           // console.error(error);
         });
       }
     }
     function onTextChange1(e) {
      
   
   
       // console.log("name")
       const value = e.target.value;
       const names = e.target.name;
       setState({ ...state, [names]: value })
       // console.log("name", names)
      
       if(value.length>1){
         setLoading(true)
         setOpen(false)
         setModal(true)
       let senddataa = {
         keyword: value,
         country: ""
       };
       if (names == "from_postal_code") {
         senddataa.country = state.from_country
       }
       else {
         senddataa.country = state.to_country
       }
       // console.log("senddata", senddataa)
       let url = config.apiUrl + '/country/search_city_postcode';
       axios.post(url, senddataa, { headers: config.headers })
         .then((responseJson) => {
           // console.log("responseJson======> ", responseJson.data.output);
   
           // let req = responseJson.data.output
           setRState(responseJson.data.output)
           setLoading(false)
   
         })
         .catch((error) => {
           //Hide Loader
           // console.error(error);
         });
       }}
   
     function selectedText(value, text) {
       // console.log("eee----->", value)
       // console.log("ttttt----->", text)
       setModal1(false)
       setState({ ...state, from_postal_code: value, from_city: text })
       setPostalCode([])
   
     }
   
     function selectedText1(value, text) {
       // console.log("value", value)
       // console.log("value---->", text)
       setModal(false)
       setState({ ...state, to_postal_code: value, to_city: text })
       setRState([])
   
     }
   
     function addRows(type, index) {
       // console.log("type    ", type, " . ", index)
       let items = [...rowstate];
       // console.log("items    ", items)
       if (type == 1) {
         let newItem = { weight: "", height: "", width: "", length: "", buttonType: "remove",error:{} };
         // console.log("newItem  =========>   ", newItem)
   
   
   
   
         //for (var i = 0; i <= rowstate.length; i++) {
   
         items.push(newItem);
         //}
         // console.log("items  =========>   ", items)
   
         setRowState(items)
       } else if (type == 2) {
         items.splice(index, 1);
         //const newPeople = items.filter((person,index) => index == index);
         setRowState(items)
       }
     }
   
   
     
   
   
     function openModal() {
   
       // console.log("sdf")
       setOpen(true)
     }
     function closeModal() {
   
       // console.log("sdf")
       setOpen(false)
       // setModal(false)
   
     }
     function openModal1() {
   
       // console.log("sdf")
       setOpen1(true)
     }
     function closeModal1() {
   
       // console.log("sdf")
       setOpen1(false)
       //  setModal1(false)
   
     }
     
   
     const countryDropDown = (e) => {
       const value = e.target.value;
       //const names = e.target.name;
       // console.log("value", value)
       if(value == "") {
         setState({ ...state, from_country_type: value,from_country:"",from_country_name:"" })
         
       }else {
         setState({ ...state, from_country_type: value,from_country:"" })
       }
   
       
       setcountrymodal(true)
       get_country_list(value)
   
     }
   
     const countryDropDownF = (e) => {
       //const value = e.target.value;
       //const names = e.target.name;
       // console.log("value", e)
   
   
      // setState({ ...state, from_country_type: value,from_country:"" })
      setcountrymodal1(false)
       setcountrymodal(true)
       get_country_list()
     }
   
  
   
     const countryDropDown1 = (e) => {
       const value = e.target.value;
       //const names = e.target.name;
       // console.log("value", value)
   
       if(value == "") {
       setState({ ...state, to_country_type: value,to_country:"",to_country_name:"" })
       }else {
         setState({ ...state, to_country_type: value,to_country:"" })
       }
       setcountrymodal1(true)
       get_country_list(value)
   
     }
   
   
     
    function onKeyPressed(e) {
       // console.log("key",e.key);
        
       if(e.key=="ArrowDown"){
         let nextIndexCount = 0
         nextIndexCount=(focusedIndex+1) % country.length
         setfocusedIndex(nextIndexCount)
       }
       if(e.key=="ArrowUp"){
         let nextIndexCount = 0
         nextIndexCount=(focusedIndex + country.length - 1) % country.length
         setfocusedIndex(nextIndexCount)
       }
       if(e.key=="Enter"){
          e.preventDefault()
          handleselection(focusedIndex)
         
       }
     }
     function onKeyPressedpostalcode(e) {
       // console.log("key",e.key);
        
       if(e.key=="ArrowDown"){
         let nextIndexCount = 0
         nextIndexCount=(focusedIndex+1) % postalcode.length
         setfocusedIndex(nextIndexCount)
       }
       if(e.key=="ArrowUp"){
         let nextIndexCount = 0
         nextIndexCount=(focusedIndex + postalcode.length - 1) % postalcode.length
         setfocusedIndex(nextIndexCount)
       }
       if(e.key=="Enter"){
          e.preventDefault()
          handleselectionfrompostal(focusedIndex)
         
       }
     }
     function handleselection(index){
       // console.log("index----->", country[index])
      setState({ ...state,from_country_type:country[index].country_name, from_country: country[index].country_id, from_country_code: country[index].country_code, from_country_name: country[index].country_name })
         setcountrymodal(false)
         
     }
     function handleselectionfrompostal(index){
       // console.log("index----->", postalcode[index])
       setModal1(false)
       setState({ ...state, from_postal_code: postalcode[index].postal_code, from_city: postalcode[index].city_name})
       setPostalCode([])
       setOpen1(false)
         
     }
     
     function onKeyPressedto_country(e) {
       // console.log("key",e.key);
        
      
          if (e.key === "ArrowUp") {
            setSelectedItem((prev) => Number(prev) - 1);
            scrollRef.current.scrollBy(0, -18);
          }
          if (e.key === "ArrowDown") {
            setSelectedItem((prev) => Number(prev) + 1);
                  //  scrollRef.current.scrollBy(0, 18);
          }
      
        // window.addEventListener("keydown", keyPress);
    
        // return () => {
        //   window.removeEventListener("keydown", keyPress);
        // };
      
      //  if(e.key=="ArrowUp"){
      //    let nextIndexCount = 0
      //    nextIndexCount=(focusedIndex + country.length - 1) % country.length
      //    setfocusedIndex(nextIndexCount)
      //  }
       if(e.key=="Enter"){
          e.preventDefault()
          select_to_country(focusedIndex)
         
       }
     }
     function select_to_country(index){
       // console.log("index----->", country[index])
      setState({ ...state,to_country_type:country[index].country_name, to_country: country[index].country_id, to_country_code: country[index].country_code, to_country_name: country[index].country_name })
         setcountrymodal1(false)
         
     }
     function onKeyPressedto_postalcode(e) {
       // console.log("key",e.key);
        
       if(e.key=="ArrowDown"){
         
         let nextIndexCount = 0
         nextIndexCount=(focusedIndex+1) % rstate.length
         setfocusedIndex(nextIndexCount)
       }
       if(e.key=="ArrowUp"){
         let nextIndexCount = 0
         nextIndexCount=(focusedIndex + rstate.length - 1) % rstate.length
         setfocusedIndex(nextIndexCount)
       }
       if(e.key=="Enter"){
          e.preventDefault()
          select_to_postal_code(focusedIndex)
         
       }
     }
     function select_to_postal_code(index){
       setModal(false)
       setState({ ...state, to_postal_code: rstate[index].postal_code, to_city: rstate[index].city_name })
       setRState([])
       setOpen(false)
         
     }
  // useEffect(() => {
  //   const keyPress = (e) => {
  //     if (e.key === "ArrowUp") {
  //       setSelectedItem((prev) => Number(prev) - 1);
       
  //       scrollRef.current.scrollBy(0, -18);
  //     }
  //     if (e.key === "ArrowDown") {
  //       setSelectedItem((prev) => Number(prev) + 1);
  //       scrollRef.current.scrollBy(0, 18);
  //     }
  //   };
  //   window.addEventListener("keydown", keyPress);

  //   return () => {
  //     window.removeEventListener("keydown", keyPress);
  //   };
  // }, [selectedItem]);

  const onClickDiv = (e) => {
    setSelectedItem(e.target.id);
  };
  const renderList = () => {
    let items = [];
    // console.log(selectedItem);
    for (let i = 0; i < 60; i++) {
      items.push(
        <div
          key={i}
          className={`item ${Number(selectedItem) === i ? "active bg-dark" : ""}`}
          id={i}
          onClick={onClickDiv}
        >
          Item{i}.png
        </div>
      );
    }
    return items;
  };
  return (
    <div className="App">
      {/* <div className="list-container" ref={scrollRef}>
        <div className='card'>{renderList()}</div>
        
      </div> */}
      <Header_nav/>
       <div class="card-body">
                      <div class="row">
                        <h6>From</h6>
                          <div class="col-12 col-md mb-3">
                          <div className="form-floating"onKeyDown={onKeyPressed} tabIndex={1}>
                        <input className="form-control" id="sel1" name="from_country_type"  onChange={countryDropDown} onFocus={countryDropDownF} placeholder="Country" value={state.from_country_type} style={state.error.from_country?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}} autoComplete="off"/>
                           {countrymodal == true ?
                          <div class="col-12 mt-2  bg-white shadow-lg "   style={{maxHeight: "160px",zIndex:23,overflowY: "auto",position:"absolute"}}  >
                               {load == false ?
                               country.map((sub, index) =>{
                                return(
                                <div className="list-main ">
                                  <div className="" ref={index===focusedIndex?resultContainer:null} key={index} 
                                    style={{
                                    listStyle: "none",
                                    backgroundColor: index==focusedIndex? "rgb(255, 230, 230)": "",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.42657",
                                    // overflowY:"scroll",
                                    // height:"400px",
                                    // fontWeight:  index==focusedIndex? "700": "400",
                                    letterSpacing: "0.03em",
                                    display: "flex",
                                    textAlign: "left",
                                    width: "100%",
                                    padding: "10px 16px",
                                    height: "48px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    // color: "rgb(112, 111, 111)"
                                  }}
                                   onClick={() => selectcountry(sub)}>
                                  <span style={{fontWeight:  index==focusedIndex? "700": ""}}>{sub.country_name}</span>  
                                      </div>
                                  </div>)
                               }) 
                                :
                                 <li  style={{
                                    listStyle: "none",
                                     backgroundColor: "rgb(255, 230, 230)",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.42657",
                                    fontWeight: "400",
                                    letterSpacing: "0.03em",
                                    display: "flex",
                                    textAlign: "left",
                                    width: "100%",
                                    padding: "10px 16px",
                                    height: "48px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderColor:"rgb(167, 37, 20)"
                                  }}
                                  className="form-control"
                                >
                                  Loading...
                                </li>}

                          </div> : ""}
                               <label for="sel1" className="form-label" style={{fontSize:"14px",fontWeight:700,fontFamily:"proxima-nova, sans-serif",color:"rgb(55, 55, 55)"}}>Country</label>

                      </div>
                          </div>
                          <div class="col-12 col-md-6 mb-3">
                          <div className="form-floating" onKeyDown={onKeyPressedpostalcode} tabIndex={1}>
                        {state.from_country_name != "" ?
                          <input className="form-control" id="sel1" name="from_postal_code" onChange={onTextChange} onFocus={openModal1}
                            onBlur={closeModal1} value={state.from_postal_code} placeholder="City or Postalcode" style={state.error.from_postal_code?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}}  autoComplete='off'/>
                          :
                          <input className="form-control" autoComplete="off" id="sel1" name="from_postal_code" onChange={onTextChange} onFocus={openModal1}
                            onBlur={closeModal1}
                            value={state.from_postal_code} placeholder="City or Postalcode" disabled style={state.error.from_postal_code?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}}/>}
                        {isopen1 == true ?
                            <div class="col-sm-12 mt-2  bg-white shadow-lg " style={{maxHeight: "160px",zIndex:23,overflowY: "auto",position:"absolute"}}>
                                <li
                                  style={{
                                    listStyle: "none",
                                     backgroundColor: "rgb(255, 230, 230)",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.42657",
                                    fontWeight: "400",
                                    letterSpacing: "0.03em",
                                    display: "flex",
                                    textAlign: "left",
                                    width: "100%",
                                    padding: "10px 16px",
                                    height: "48px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    borderColor:"rgb(167, 37, 20)"
                                  }}
                                  className="form-control"
                                >
                                  Add City or Postal Code
                                </li>
                            </div>
                           : ""}
                        {ismodal1 == true ?
                          <div class="col-sm-12 mt-2 bg-white shadow-lg " style={{maxHeight: "160px",zIndex:23,overflowY: "auto",position:"absolute"}} >
                             {isloading1 == false ?
                                <div className='list-main'>
                                  {postalcode.length > 0 ?
                                    postalcode.map((item, index) => (
                                      <div className="">
                                      <div className='' ref={index===focusedIndex?resultContainer:null} key={index} style={{
                                        listStyle: "none",
                                        backgroundColor: index==focusedIndex? "rgb(255, 230, 230)": "",
                                        fontSize: "0.875rem",
                                        lineHeight: "1.42657",
                                        // fontWeight: "400",
                                        letterSpacing: "0.03em",
                                        display: "flex",
                                        textAlign: "left",
                                        width: "100%",
                                        padding: "10px 16px",
                                        height: "48px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => selectedText(item.postal_code, item.city_name)}
                                      >
                                         <span style={{fontWeight:  index==focusedIndex? "700": ""}}>{item.postal_code} {item.city_name}, {item.additive} </span>  </div></div>
                                    )) : <li
                                      style={{
                                          listStyle: "none",
                                          backgroundColor: "rgb(255, 230, 230)",
                                          fontSize: "0.875rem",
                                          lineHeight: "1.42657",
                                          fontWeight: "400",
                                          letterSpacing: "0.03em",
                                          display: "flex",
                                          textAlign: "left",
                                          width: "100%",
                                          padding: "10px 16px",
                                          height: "48px",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          cursor: "pointer",
                                          borderColor:"rgb(167, 37, 20)"
                                      }}
                                      className="form-control"
                                    >
                                      No Postal Code
                                    </li>}
                                </div>
                                :
                                <li
                                style={{
                                  listStyle: "none",
                                  backgroundColor: "rgb(255, 230, 230)",
                                  fontSize: "0.875rem",
                                  lineHeight: "1.42657",
                                  fontWeight: "400",
                                  letterSpacing: "0.03em",
                                  display: "flex",
                                  textAlign: "left",
                                  width: "100%",
                                  padding: "10px 16px",
                                  height: "48px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  borderColor:"rgb(167, 37, 20)"
                                }}
                                  className="form-control"
                                >
                                  Loading...
                                </li>}

                            
                          </div>
                          : ""}


                        <label for="sel1" className="form-label" style={{fontSize:"14px",fontWeight:700,fontFamily:"sans-serif"}}>City or Postalcode</label>

                      </div>
                          </div>
                      </div>
          
                      <div class="row mt-3">
                        <h6>To</h6>
                          <div class="col-12 col-md-6 mb-3">
                          <div className="form-floating" onKeyDown={onKeyPressedto_country}>
                        <input className="form-control" id="sel1" name="to_country_type" onChange={countryDropDown1} onFocus={countryDropDown1} placeholder="Country" value={state.to_country_type} style={state.error.to_country?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}} autoComplete="off"/>


                        {countrymodal1 == true ?
                          <div class="col-sm-12 mt-2 bg-white shadow-lg " style={{maxHeight: "160px",zIndex:23,overflowY: "auto",position:"absolute"}}>
                            {load == false ?
                               country.map((sub, index) =>{
                                return(
                                <div className="list-main">
                                  <div className={`sub ${Number(selectedItem) === index ? "active bg-dark" : ""}`} ref={scrollRef} key={index} 
                                    style={{
                                    listStyle: "none",
                                    // backgroundColor: index==focusedIndex? "rgb(255, 230, 230)": "",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.42657",
                                    
                                    // fontWeight:  index==focusedIndex? "700": "400",
                                    letterSpacing: "0.03em",
                                    display: "flex",
                                    textAlign: "left",
                                    width: "100%",
                                    padding: "10px 16px",
                                    height: "48px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    // color: "rgb(112, 111, 111)"
                                  }}
                                   onClick={() => selectcountry1(sub)}>
                                  <span style={{fontWeight:  index==focusedIndex? "700": ""}}>{sub.country_name}</span>  
                                      </div>
                                  </div>)
                               }) 
                                : <li
                                style={{
                                  listStyle: "none",
                                   backgroundColor: "rgb(255, 230, 230)",
                                  fontSize: "0.875rem",
                                  lineHeight: "1.42657",
                                  fontWeight: "400",
                                  letterSpacing: "0.03em",
                                  display: "flex",
                                  textAlign: "left",
                                  width: "100%",
                                  padding: "10px 16px",
                                  height: "48px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  borderColor:"rgb(167, 37, 20)"
                                }}
                                  className="form-control"
                                >
                                  Loading...
                                </li>}

                            
                          </div> : ""}
                       
                        <label for="sel1" className="form-label" style={{fontSize:"14px",fontWeight:700,fontFamily:"sans-serif"}}>Country</label>

                      </div>
                          </div>
                          <div class="col-12 col-md-6 mb-3">
                          <div className="form-floating" onKeyDown={onKeyPressedto_postalcode} tabIndex={-1}>
                        {state.to_country_name != "" ?
                          <input className="form-control" id="sel1" name="to_postal_code" onChange={onTextChange1} onFocus={openModal}
                            onBlur={closeModal}    value={state.to_postal_code} placeholder="City or Postalcode" 
                            style={state.error.to_postal_code?{borderColor:"red",borderWidth:"1px"}:{borderColor:"#373737"}} autoComplete='off'
                            />
                            :
                          <input className="form-control" autoComplete="off" id="sel1" name="to_postal_code" onChange={onTextChange1} onFocus={openModal}
                            onBlur={closeModal}
                            value={state.to_postal_code} placeholder="City or Postalcode" disabled 
                            style={state.error.to_postal_code?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}}
                            />}
                        {isopen == true ?
                         
                            <div class="col-sm-12 mt-2 bg-white shadow-lg " style={{maxHeight: "160px",zIndex:23,overflowY: "auto",position:"absolute"}} >
                             
                                <li
                                  style={{
                                    listStyle: "none",
                                     backgroundColor: "rgb(255, 230, 230)",
                                    fontSize: "0.875rem",
                                    lineHeight: "1.42657",
                                    fontWeight: "400",
                                    letterSpacing: "0.03em",
                                    display: "flex",
                                    textAlign: "left",
                                    width: "100%",
                                    padding: "10px 16px",
                                    height: "48px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    borderColor:"rgb(167, 37, 20)"
                                  }}
                                  className="form-control"
                                >
                                  Add City or Postal Code
                                </li>
                              </div>
                           
                         : ""}
                        {ismodal == true ?
                          <div class="col-sm-12 mt-2 bg-white shadow-lg " style={{maxHeight: "160px",zIndex:23,overflowY: "auto",position:"absolute"}}>
                           
                              {isloading == false ?
                                <div>
                                  {rstate.length > 0 ?
                                    rstate.map((item, index) => (
                                       <div className='list-main'>
                                      <div className='' ref={index===focusedIndex?resultContainer:null} key={index} style={{
                                        listStyle: "none",
                                        backgroundColor: index==focusedIndex? "rgb(255, 230, 230)": "",
                                        fontSize: "0.875rem",
                                        lineHeight: "1.42657",
                                        // fontWeight: "400",
                                        // overflowY:"scroll",
                                        letterSpacing: "0.03em",
                                        display: "flex",
                                        textAlign: "left",
                                        width: "100%",
                                        padding: "10px 16px",
                                        height: "48px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => selectedText1(item.postal_code, item.city_name)} 
                                      >
                                        <span style={{fontWeight:  index==focusedIndex? "700": ""}}> {item.postal_code} {item.city_name}, {item.additive}</span>   </div>
                                        </div>
                                    )) : <li
                                    style={{
                                      listStyle: "none",
                                       backgroundColor: "rgb(255, 230, 230)",
                                      fontSize: "0.875rem",
                                      lineHeight: "1.42657",
                                      fontWeight: "400",
                                      letterSpacing: "0.03em",
                                      display: "flex",
                                      textAlign: "left",
                                      width: "100%",
                                      padding: "10px 16px",
                                      height: "48px",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      cursor: "pointer",
                                      borderColor:"rgb(167, 37, 20)"
                                    }}
                                      className="form-control"
                                    >
                                      No Postal Code
                                    </li>}
                                </div>
                                :
                                <li
                                style={{
                                  listStyle: "none",
                                   backgroundColor: "rgb(255, 230, 230)",
                                  fontSize: "0.875rem",
                                  lineHeight: "1.42657",
                                  fontWeight: "400",
                                  letterSpacing: "0.03em",
                                  display: "flex",
                                  textAlign: "left",
                                  width: "100%",
                                  padding: "10px 16px",
                                  height: "48px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  borderColor:"rgb(167, 37, 20)"
                                }}
                                  className="form-control"
                                >
                                  Loading...
                                </li>}
                            
                          </div>
                          : ""}
                        <label for="sel1" className="form-label" style={{fontSize:"14px",fontWeight:700,fontFamily:"sans-serif"}}>City or Postalcode</label>
                      </div>
                          </div>
                      </div>
               
                      <div class="row mt-3">
                          <h6>Parcel</h6>
                          {rowstate.map((sub, index) => (
                      <div class="row ">
                        <div class="col-12 col-md-3 ">
                          <div class="form-floating mb-3 ">
                            <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter weight" name="weight" onChange={(e) => handleChange2(e, index)} value={sub.weight} style={sub.error.weight?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}}/>
                            <label for="" style={{fontSize:"14px",fontWeight:700,fontFamily:"sans-serif"}}>Weight ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</label>
                            {(sub.error.weight)?
                            <>
                            <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                            lineHeight: "1.66667",
                            fontWeight: "400",
                            letterSpacing: "0.05em",marginLeft:"4px"}}>Weight is required</small></>:""}
                          </div>
                        </div>
                        <div class="col-12 col-md ">
                          <div class="row g-0">
                            <div class="col-12 col-md-4">
                              <div class="form-floating mb-3 ">
                                <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter height" name="height" onChange={(e) => handleChange2(e, index)} value={sub.height} style={sub.error.height?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}}/>
                                <label for="" style={{fontSize:"14px",fontWeight:700,fontFamily:"sans-serif"}}>Height ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_dimension}</>
                                 ))})</label>
                                {(sub.error.height)?
                               <>
                               <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                               lineHeight: "1.66667",
                               fontWeight: "400",
                               letterSpacing: "0.05em",marginLeft:"4px"}}>Height is required</small></>:""}
                              </div>
                            </div>
                            <div class="col-12 col-md-4">
                              <div class="form-floating mb-3 ">
                                <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter width" name="width" onChange={(e) => handleChange2(e, index)} value={sub.width} style={sub.error.width?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}}/>
                                <label for="" style={{fontSize:"14px",fontWeight:700,fontFamily:"sans-serif"}}>Width ({dimensionState.companyDetail.map((subscriber)=>( 
                         
                         subscriber.set_dimension ))})</label>
                                {(sub.error.width)?
                                <>
                                <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                                lineHeight: "1.66667",
                                fontWeight: "400",
                                letterSpacing: "0.05em",marginLeft:"4px"}}>Width is required</small></>:""}
                              </div>
                            </div>
                            <div class="col-12 col-md-4">
                              <div class="form-floating mb-3 ">
                                <input type="number" autoComplete="off" className="form-control" id="" placeholder="Enter length" name="length" onChange={(e) => handleChange2(e, index)} value={sub.length} style={sub.error.length?{borderColor:"rgb(167, 37, 20)",borderWidth:"1px"}:{borderColor:"#373737"}}/>
                                <label for="" style={{fontSize:"14px",fontWeight:700,fontFamily:"sans-serif"}}>Length ({dimensionState.companyDetail.map((subscriber)=>( 
                         
                         subscriber.set_dimension ))})</label>
                                {(sub.error.length)?<>
                                 <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                                 lineHeight: "1.66667",
                                 fontWeight: "400",
                                 letterSpacing: "0.05em",marginLeft:"4px"}}>Length is required</small></>:""}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-md-1 ">
                          {(sub.buttonType == "add") ?
                            <span class=""><i class="material-icons mt-3" onClick={() => addRows(1)} style={{ cursor: "pointer", color: "red" }}>add_circle_outline</i></span> :
                            <span class=""><i class="material-icons mt-3 " onClick={() => addRows(2, index)} style={{ cursor: "pointer", color: "red" }}>remove_circle_outline</i></span>
                          }
                        </div>
                      </div>

                    ))}
                      </div>
                      <div>
                        <div class="d-grid mt-3">
                        <button className="btn btn-main btn-block btn-lg" onClick={() => { ship_now() }} >Ship Now</button>
                        </div>
                      </div>
                    </div>
                    <Footer/>
    </div>
  );
}

export default Demo_page
