import React from 'react'
import Customer_header from './Customer_header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import Footer from './Footer'
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../config"
import Moment from 'react-moment';
import Header_nav from './Header_nav';
import download from 'downloadjs'
import Shipment_steps from './Shipment_steps';

const Ship_1 = () => {
    let navigate = useNavigate()
    const [defaultCountry, setDefaultCountry] = React.useState({country_id:0})

    const [selectedOption, setSelectedOption] = React.useState(null);
    const [selectedOption1, setSelectedOption1] = React.useState(null);

    const [options, setOptions] = React.useState([]);
    const[addstate , addSetState] = React.useState({sender_country: "",  sender_country_code: "", sender_postal_code: "",sender_state_name:"", sender_city: "", sender_address_id : "",sender_city_id:"", sender_name:"" , sender_mobile:"" , sender_email:"", sender_address: "",receiver_name:"",receiver_email:"",receiver_mobile:"",receiver_address:"",receiver_country: "", receiver_country_code: "", receiver_postal_code: "", receiver_city: "", receiver_state_name:"", mode:"",error:{} });
    // console.log("addstate==== " , addstate)
    const[domesticmode,setdomesticmodeState] = React.useState({domestic:false})
    const [returnstate, setReturnState] = React.useState({return_name:"",return_city:"",return_country_code:"",return_postal_code:"", return_mobile: "", return_address: "", return_email: "",return_state_name:"" , return_country:""})
    // console.log("returnstate === " , returnstate)
    const[checkboxstate,setcheckboxState]=React.useState(true)
    const[internationalmode,setinternationalmodeState] = React.useState({international:false})
    const[addressState, setaddressState] = React.useState([]);
    const[selected,setSelected ] = React.useState(null)
    const [errorState, setErrorState] = React.useState({receiver_mobile:"" , receiver_email:"" })
    const [countryList, setcountryList] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [pincodeList, setPincodeList] = React.useState([]);
    const[country,setCountry] = React.useState({country_id:""})
    const[state,setState] = React.useState({state_id:""})
    const[city,setCity] = React.useState({city_id:""})
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

    React.useEffect(()=>{
        address_book_detail()
        getCountryList()
    },[])
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
    const address_book_detail = ()=>{
        let url = config.apiUrl + '/country/address_book';
        let sendData = {  customer_id: userData.customer_id };
        // console.log("bb", sendData);
        axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
            if(res.data.status==true){
                // console.log("sasdfdsa")
                setaddressState(res.data.output)
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }
       const selectAddress =(index , sub)=>{
         setSelected(index)
         addSetState({...addstate , sender_country:sub.country_name , sender_city:sub.city_name , sender_state_name: sub.state_name , sender_postal_code:sub.pincode , sender_country_code:sub.country_code  , sender_address_id : sub.address_id  , sender_name:sub.full_name , sender_mobile:sub.mobile , sender_email:sub.email , sender_address: sub.address , sender_city_id:sub.city_id})
         if(checkboxstate==true){
            setReturnState({...returnstate,return_name:sub.full_name, return_mobile:sub.mobile, return_address:sub.address, return_email:sub.email , return_state_name:sub.state_name , return_city:sub.city_name , return_country:sub.country_name , return_country_code:sub.country_code , return_postal_code:sub.pincode })
            }
        
       }
       const onChangeLocationGeneral = (e) => {
        // console.log("e general name ",e.target.name);
         // console.log("e general value ",e.target.value);
         let values =   Object.assign({},e.target.value.split(',') )
        
         setCountry({country_id:values[0]})
         addSetState({...addstate , receiver_country:values[1] , receiver_country_code:values[2]  })
            getStateList(values[0])
       
      }
      const onChangeLocationstate = (e) => {
        // console.log("e general name ",e.target.name);
         // console.log("e general value ",e.target.value);
         
         
       let values =   Object.assign({},e.target.value.split(',') )
       // console.log("first",values[0])
       setState({state_id:values[0]})
        addSetState({...addstate , receiver_state_name:values[2] , receiver_city:"" , receiver_postal_code:""})
        setSelectedOption(null)
        setOptions([])
        setCityList([])
         getCityList({state_id:values[0], country_id:values[1]})
       
      }
      const onChangeLocationcity = (e) => {
        // console.log("e general name ",e.target.name);
         // console.log("e general value ",e.target.value);
         let values =   Object.assign({},e.target.value.split(',') )

        setCity({city_id:values[0]})
        addSetState({...addstate , receiver_city:values[1] , receiver_postal_code:""})
         getPincodeList(values[0])
         setSelectedOption(null)
      
       
      }
      const getCountryList = () => {


        let url = config.apiUrl + '/country/country_list';
        let sendData = {limit:300 , status:1};
        // // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // // console.log("res ", res);           
            setcountryList(res.data.output)
    
    
        }).catch((e) => {
    
    
        });
    }
    const getStateList = (country_id) => {


        let url = config.apiUrl + '/country/state_list';
        let sendData = { t_country_id: country_id, limit: 1000 };
        //  // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // // console.log("state ", res);           
            setStateList(res.data.output)
    
    
        }).catch((e) => {
    
    
        });
    }
    const getPincodeList = (value) => {
      
        // console.log("value",value)
        let url = config.apiUrl + '/country/pincode_list';
           let sendData = { city_id: value };
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               // // console.log("city  ", res);           
               setPincodeList(res.data.output)
               let data = res.data.output
               const transformedOptions = data.map(item => ({
                value: item.postal_code, // Set the value property based on your data
                label: item.postal_code // Set the label property based on your data
              }));
           setOptions(transformedOptions)
       
       
       
           }).catch((e) => {
       
       
           });
       }
      const getCityList = (value) => {
      
       // console.log("value",value)
       let url = config.apiUrl + '/country/city_list';
          let sendData = { state_id: value.state_id,country_id:value.country_id, limit: 1000 };
          // console.log("bb", sendData);
          axios.post(url, sendData, { headers: config.headers }).then((res) => {
              // // console.log("city  ", res);           
              setCityList(res.data.output)
      
      
          }).catch((e) => {
      
      
          });
      }
      const handleChange=(e)=>{
        // // console.log("namess",e.target.name)
        // // console.log("value",e.target.value)
        
        let names = e.target.name
        let value= e.target.value
       
        if(e.target.type=="text"){
            addSetState({...addstate,[e.target.name]:value})
        }
        else if(e.target.type=="radio"){
            if(e.target.checked==true){
                if(e.target.name=="mode_domestic"){
                    setdomesticmodeState({...domesticmode,domestic:true})
                    setinternationalmodeState({...internationalmode,international:false})
                    //  addSetState({...addstate,mode:"Domestic"})
                         let url = config.apiUrl + '/country/country_list';
                         let senddata = {}
                         axios.post(url, senddata, { headers: config.headers })
                           .then((responseJson) => {
                              // console.log("responseJson => ", responseJson.data.defaultCountry);
                        
                             setDefaultCountry(responseJson.data.defaultCountry)
                             addSetState({...addstate ,mode:"Domestic", receiver_country:responseJson.data.defaultCountryName , receiver_country_code:responseJson.data.defaultCountrycode , receiver_city:"" , receiver_state_name:"" , receiver_postal_code:""})
                             setStateList([])
                             setCityList([])
                             setSelectedOption(null)
                             setOptions([])
                              setCountry({country_id:responseJson.data.defaultCountry})
                            //  setaddState({...addstate , country_name:responseJson.data.defaultCountry})
                                getStateList(responseJson.data.defaultCountry)
                        
                           })
                           .catch((error) => {
                             
                             // console.error(error);
                           });
                       

                }
                else if(e.target.name=="mode_international"){
                    setdomesticmodeState({...domesticmode,domestic:false})
                    setinternationalmodeState({...internationalmode,international:true})
                     addSetState({...addstate,mode:"International" , receiver_country:"" , receiver_country_code:"" , receiver_city:"" , receiver_postal_code:"" , receiver_state_name:""})
                     setSelectedOption(null)
                     setOptions([])
                   
                     setCountry({country_id:""})
                     setCityList([])
                   //  setaddState({...addstate , country_name:responseJson.data.defaultCountry})
                  setStateList([])
                }
              
            }
        }
        else if(e.target.type=="number"){
            var re = new RegExp("^.{10}$");
            // // console.log(value,"   regex number check =   ",re.test(value))
            setErrorState({ ...errorState, receiver_mobile: "" })
            if (re.test(value)) {
              re = new RegExp("^[0-9]+$");
              if (!re.test(value)) {
                setErrorState({ ...errorState, receiver_mobile: "Please enter valid number" })
                //// console.log("please enter valid number")
              }
            } else {
              setErrorState({ ...errorState, receiver_mobile: "Please enter 10 digit number " })
             // return(null)
              //// console.log("please check length")
            }
            addSetState({...addstate,[e.target.name]:value})
        }
        else if(e.target.type=="email"){
            setErrorState({ ...errorState, receiver_email: "" })
            addSetState({...addstate , receiver_email:e.target.value})
        }
       
       
       
    }
    const onChangeLocationpincode =(e)=>{
        addSetState({...addstate,receiver_postal_code:e.target.value})
    }
    const handleSelectChange = selectedOption => {
        setSelectedOption(selectedOption);
         addSetState({...addstate, receiver_postal_code:selectedOption})
      };
    
      
    const next_step=()=>{

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // if ( re.test(addstate.receiver_email) ) {
          if(addstate.mode!=""){
             if(addstate.receiver_name!=""){
                 if(addstate.receiver_email!=""){
                    if ( re.test(addstate.receiver_email) ) {
                    if(addstate.receiver_mobile!=""){
                        if(errorState.receiver_mobile==""){
                            if(addstate.receiver_address!=""){
                     if(addstate.receiver_country!=""){
                      if(addstate.receiver_state_name!=""){
                         if(addstate.receiver_city!=""){
                            if(addstate.receiver_postal_code.value!=""){
                               if(addstate.sender_name!="" && addstate.sender_address!=""){
                                if(returnstate.return_name!=""){
                                    if(returnstate.return_email!=""){
                                    if(returnstate.return_mobile!=""){
                                    if(returnstate.return_address!=""){

                                navigate({
                                    pathname: '/ship_2',
                                    search: `sender_name=${addstate.sender_name}&sender_address=${addstate.sender_address}&sender_country=${addstate.sender_country}&sender_country_code=${addstate.sender_country_code}&sender_postal_code=${addstate.sender_postal_code}&sender_state_name=${addstate.sender_state_name}&sender_city=${addstate.sender_city}&sender_address_id=${addstate.sender_address_id}&sender_city_id=${addstate.sender_city_id}&sender_mobile=${addstate.sender_mobile}&sender_email=${addstate.sender_email}&receiver_name=${addstate.receiver_name}&receiver_email=${addstate.receiver_email}&receiver_mobile=${addstate.receiver_mobile}&receiver_address=${addstate.receiver_address}&receiver_country=${addstate.receiver_country}&receiver_country_code=${addstate.receiver_country_code}&receiver_postal_code=${addstate.receiver_postal_code.value}&receiver_city=${addstate.receiver_city}&receiver_state_name=${addstate.receiver_state_name}&mode=${addstate.mode}&return_name=${returnstate.return_name}&return_email=${returnstate.return_email}&return_mobile=${returnstate.return_mobile}&return_address=${returnstate.return_address}&return_country=${returnstate.return_country}&return_country_code=${returnstate.return_country_code}&return_postal_code=${returnstate.return_postal_code}&return_city=${returnstate.return_city}&return_state_name=${returnstate.return_state_name}`,
                                  });
                                }else{
                                    Toast.fire({
                                        background:"rgb(231, 91, 75)",
                                        type: 'unsuccess',
                                        title: "Please Fill Return Address",
                                        color:"white"
                                      });
                                }
                                }else{
                                    Toast.fire({
                                        background:"rgb(231, 91, 75)",
                                        type: 'unsuccess',
                                        title: "Please Fill Return Mobile",
                                        color:"white"
                                      });
                                }
                                }else{
                                    Toast.fire({
                                        background:"rgb(231, 91, 75)",
                                        type: 'unsuccess',
                                        title: "Please Fill Return Email",
                                        color:"white"
                                      });
                                }
                                }else{
                                    Toast.fire({
                                        background:"rgb(231, 91, 75)",
                                        type: 'unsuccess',
                                        title: "Please Fill Return Name",
                                        color:"white"
                                      });
                                }
                                 }
                               else{
                                Toast.fire({
                                    background:"rgb(231, 91, 75)",
                                    type: 'unsuccess',
                                    title: "Please Select Sender Detail",
                                    color:"white"
                                  });
                               }
                            }
                            else{
                                Toast.fire({
                                    background:"rgb(231, 91, 75)",
                                    type: 'unsuccess',
                                    title: "Please Select Receiver Postal Code",
                                    color:"white"
                                  });
                            }
                         }
                         else{
                            Toast.fire({
                                background:"rgb(231, 91, 75)",
                                type: 'unsuccess',
                                title: "Please Select Receiver City",
                                color:"white"
                              });
                         }
               }
               else{
                Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'unsuccess',
                    title: "Please Select Receiver State",
                    color:"white"
                  });
               }
            }
            else{
                Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'unsuccess',
                    title: "Please Select Receiver Country",
                    color:"white"
                  });
            }
        }
        else{
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Fill Receiver Address",
                color:"white"
              });
        }
        }else{
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Enter Valid 10 digit Number",
                color:"white"
              });
        }
        }
        else{
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Fill Receiver Mobile",
                color:"white"
              });
        }
    }else{
        setErrorState({...errorState , receiver_email:"Please Enter Valid Email"})
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Enter Valid Receiver Email",
                color:"white"
              });
    }
                 }
                 else{
                    Toast.fire({
                        background:"rgb(231, 91, 75)",
                        type: 'unsuccess',
                        title: "Please Fill Receiver Email",
                        color:"white"
                      });
                 }
             }
             else{
                Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'unsuccess',
                    title: "Please Fill Receiver Name",
                    color:"white"
                  });
             }
          }
          else{
            
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Select Mode ",
                color:"white"
              });
          }
        // }else{
        //     setErrorState({...errorState , receiver_email:"Please Enter Valid Email"})
        //     Toast.fire({
        //         background:"rgb(231, 91, 75)",
        //         type: 'unsuccess',
        //         title: "Please Enter Valid Receiver Email",
        //         color:"white"
        //       });
        // }
    }
    const checkreturnbox=(e)=>{
        if(e.target.checked==true){
           setcheckboxState(true)
        }
        else{
          setcheckboxState(false)
          setReturnState({return_name:"",return_city:addstate.sender_city ,return_country_code:addstate.sender_country_code,return_postal_code:addstate.sender_postal_code , return_mobile: "", return_address: "", return_email: "",return_state_name:addstate.sender_state_name , return_country:addstate.sender_country})
        }
      }
      const handleChangereturn=(e)=>{
        setReturnState({...returnstate,[e.target.name]:e.target.value})
      }
  return (
    <div>
      <section class="mb-3 mt-3">
  <div class="container">
 <Shipment_steps value={1}/>
    <div class="row">

      <div class="col-12 col-md-12 mb-5">
              <div class="card border-0 shadow-sm bg-light">
                <div class="card-body">
                <h4 class="mb-3">Shipment Mode</h4>
                  <section class="mb-3">
                    <div>
                      <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio"  onChange={(e)=>handleChange(e)} name="mode_domestic" id="inlxineRadio1" value="Domestic"  checked={domesticmode.domestic==true && internationalmode.international==false}/>
                        <label class="form-check-label" for="inlineRadio1">Domestic</label>
                      </div>
                      <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="mode_international" onChange={(e)=>handleChange(e)} id="inlixneRadio2" value="International" checked={internationalmode.international==true && domesticmode.domestic==false }/>
                        <label class="form-check-label" for="inlineRadio2">International</label>
                      </div>
                    </div>
                    
                  </section>
                </div>
              </div>
            </div>


      <div class="col-12 col-md">
        <section>
          <div class="row">
          <div class="col-12 col-md-12 mb-5">
          <div class="card border-0 shadow-sm bg-light">
            <div class="card-body">
                <div className='row'>
                    <div className='col-12 col-md-8'>
                    <h4 class="mb-3">Add Receiver's Details</h4>
                    </div>
                  
                </div>
               
           
           
              <section class="mb-3">
                <small class="mb-4">To whom is the order being delivered? <span class="text-muted">(Receiver's Info)</span> </small>
                <div class="row mt-3">
                    <div class="col-12 col-md-4 mb-3">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="email" placeholder="Enter Receiver Phone number" name="receiver_name" onChange={(e)=>handleChange(e)} />
                        <label for="email">Full Name</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                      <div class="form-floating">
                        <input type="email" class="form-control" id="email" placeholder="Enter Receiver Full Name" name="receiver_email" onChange={(e)=>handleChange(e)} />
                        <label for="email">Email</label>
                      </div>
                      {(errorState.receiver_email != "") ?
                                  <label><span className="text-danger">* {errorState.receiver_email}</span></label> : ""}
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                      <div class="form-floating">
                        <input type="number" class="form-control" id="email" placeholder="Enter Receiver Email Id" name="receiver_mobile" onChange={(e)=>handleChange(e)}/>
                        <label for="email">Mobile Number</label>
                       
                      </div>
                      {(errorState.receiver_mobile != "") ?
                                  <label><span className="text-danger">* {errorState.receiver_mobile}</span></label> : ""}
                    </div>
                </div>
              </section>
              <section class="mb-3">
                <small class="mb-4">Where is the order being delivered to?? <span class="text-muted">(Receiver's Address)</span> </small>
                <div class="row mt-3">
                    <div class="col-12 col-md-12 mb-3">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="email" placeholder="Enter full address" name="receiver_address" onChange={(e)=>handleChange(e)} />
                        <label for="email">Complete Address</label>
                      </div>
                    </div>
                   
                   {domesticmode.domestic==true && internationalmode.international==false?"":
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                      <select class="form-select" name="country_name" onChange={(e)=>onChangeLocationGeneral(e)}>
            <option>Select</option>
            {countryList.map((item)=>{
            return (
              <option value={[item.country_id , item.country_name , item.country_code]}>{item.country_name}</option>
          )
           
})}
</select>
                        <label for="sel1" class="form-label">Select Country:</label>
                      </div>
                   
                    </div>
}
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                      <select class="form-select"  name="state_name" onChange={(e)=>onChangeLocationstate(e)}>
            <option>Select</option>
            {stateList.map((item)=>{
            return (
              <option value={[item.state_id,item.country_id , item.state_name]}>{item.state_name}</option>
          )
           
})}
</select>
                        <label for="sel1" class="form-label">Select State:</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                      <select class="form-select" name="city_name" onChange={(e)=>onChangeLocationcity(e)} autoComplete="off" >
            <option >Select</option>
            {cityList.map((item)=>{
            return (
              <option value={[item.city_id , item.city_name]}>{item.city_name}</option>
          )
           
})}
          </select>
                        <label for="sel1" class="form-label">Select City:</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                      {/* <select class="form-select" name="pincode"  onChange={(e)=>onChangeLocationpincode(e)} >
            <option >Select</option>
            {pincodeList.map((item)=>{
            return (
              <option value={item.postal_code}>{item.postal_code}</option>
          )
           
})}
          </select> */}
             <Select
    styles={{
        control: (provided, state) => ({
          ...provided,
          minHeight: '58px', 
        }),
      }}
      placeholder="Select Pincode"
      options={options}
      value={selectedOption}
      onChange={(e)=>handleSelectChange(e)}
      isClearable={true}
      name="pincode"
    />
                       
                      </div>
                    </div>
                </div>
              </section>
            </div>
          </div>
        </div>
   

        

            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm bg-light">
                <div class="card-body">
                    <div className='row'>
                        <div className='col-12 col-md-8'>
                        <h4 class="mb-3">Add Sender's Details</h4>
                        </div>
                        <div class="col-12 col-md-4 text-end">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={(e) => checkreturnbox(e)} defaultChecked={checkboxstate}/>
                          <label class="form-check-label" for="inlineCheckbox1">Mark as return address</label>
                        </div>
                        </div>
                    </div>
                 
                  <section class="mb-3">
                    <small class="mb-4">Where is the order being sent from? <span class="text-muted">(Senders's Info)</span> </small>
                    <div class="row mt-3 ">
                        {addressState.map((sub , index)=>(
                                <div class="col-12 col-md-4 select_address mb-2" onClick={()=>selectAddress(index , sub)}>
                                <div class={selected == index?"card border-0 shadow-sm active":"card border-0 shadow-sm"}>
                                    <div class="card-body">
                                  {sub.primary==true?  <span class="badge rounded-pill bg-danger">Primary</span>:""}
                                    <h6 class="mt-2">{sub.warehouse_name}</h6>
                                    <p class="m-0 p-0">{sub.address} , {sub.city_name} , {sub.state_name}</p>
                                    <p class="m-0 p-0">{sub.pincode}</p>
                                    <small>{sub.mobile}</small>
                                    </div>
                                </div>
                                </div>
                        ))}
                      
                       
                        <div class="col-12 col-md-4 select_address">
                          <div class="card border-0 shadow-sm ">
                            <div class="card-body">
                              <a href="/my_address_book">
                                <div class="text-center py-5">
                                  <i class="material-icons-outlined fs-3">add</i>
                                  <div> Add new Address</div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            {checkboxstate==false?
        <div class="col-12 col-md-12 mb-5">
          <div class="card border-0 shadow-sm bg-light">
            <div class="card-body">
                <div className='row'>
                    <div className='col-12 col-md-8'>
                    <h4 class="mb-3">Add Return Address Details</h4>
                    </div>
                   
                </div>
               
           
           
              <section class="mb-3">
                <small class="mb-4">To whom is the order being returned? <span class="text-muted">(Return Address Info)</span> </small>
                <div class="row mt-3">
                    <div class="col-12 col-md-4 mb-3">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="email" placeholder="Enter Receiver Phone number" name="return_name" onChange={(e)=>handleChangereturn(e)} />
                        <label for="email">Full Name</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                      <div class="form-floating">
                        <input type="email" class="form-control" id="email" placeholder="Enter Receiver Full Name" name="return_email" onChange={(e)=>handleChangereturn(e)} />
                        <label for="email">Email</label>
                      </div>
                      {(errorState.receiver_email != "") ?
                                  <label><span className="text-danger">* {errorState.receiver_email}</span></label> : ""}
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                      <div class="form-floating">
                        <input type="number" class="form-control" id="email" placeholder="Enter Receiver Email Id" name="return_mobile" onChange={(e)=>handleChangereturn(e)}/>
                        <label for="email">Mobile Number</label>
                       
                      </div>
                      {(errorState.receiver_mobile != "") ?
                                  <label><span className="text-danger">* {errorState.receiver_mobile}</span></label> : ""}
                    </div>
                </div>
              </section>
              <section class="mb-3">
                <small class="mb-4">Where is the order being delivered to?? <span class="text-muted">(Return's Address)</span> </small>
                <div class="row mt-3">
                    <div class="col-12 col-md-12 mb-3">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="email" placeholder="Enter full address" name="return_address" onChange={(e)=>handleChangereturn(e)} />
                        <label for="email">Complete Address</label>
                      </div>
                    </div>
                   
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                      <input class="form-control" name="country_name" disabled value={addstate.sender_country} onChange={(e)=>onChangeLocationGeneral(e)} />
            
                        <label for="sel1" class="form-label">Select Country:</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                      <input class="form-control"  name="state_name" onChange={(e)=>onChangeLocationstate(e)} disabled value={addstate.sender_state_name}/>
       
                        <label for="sel1" class="form-label">Select State:</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                      <input class="form-control" name="city_name" onChange={(e)=>onChangeLocationcity(e)} disabled value={addstate.sender_city} />
          
                        <label for="sel1" class="form-label">Select City:</label>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 mb-3 mt-2">
                      <div class="form-floating">
                     <input class="form-select" name="pincode" disabled onChange={(e)=>onChangeLocationpincode(e)} value={addstate.sender_postal_code} />
                     <label for="sel1" class="form-label">Select Pincode:</label>
                      </div>
                    </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        :""}

            
                 
            <div class="row mt-4">
                <div class="col-12 col-md">
                
                </div>
                <div class="col-12 col-md text-end">
                <a onClick={()=>next_step()} class="btn btn-main btn-lg">Next Step <span class="mt-5"><i class="material-icons-outlined">east</i></span></a>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>      
  </div>
</section>
    </div>
  )
}

export default Ship_1
