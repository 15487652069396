import React from 'react'
import Customer_header from './Customer_header'
import config from '../config';
const Customer_settings = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  return (
    <div style={{backgroundColor:"#f1f3f8"}}>
        <Customer_header/>
      <div class="container width_custom_0" style={{marginTop:"-680px"}}>
        
        <div class="row mt-3"  data-masonry='{"percentPosition": true }'>

                

                <div class="col-12 col-md-4 px-0c">
                        <h3>My Shipments</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/my_shipments" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">My Shipments</h6>
                                    <p class="text-secondary mb-0 small">Findout all shipments in your account</p>
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Create Shipments</h6>
                                    <p class="text-secondary mb-0 small">Create a new shipment </p>
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c">
                        <h3>My Wallet</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/my_wallet" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">My Wallet</h6>
                                    <p class="text-secondary mb-0 small">See wallet information and tracscations</p>
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c">
                        <h3>Tools</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Rate Calculator</h6>
                                    <p class="text-secondary mb-0 small">Calculate your shipment price</p>
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c">
                        <h3>User Profile</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="/profile" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">View Profile</h6>
                                    <p class="text-secondary mb-0 small">Review or Update your View Profile</p>
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href={"/edit_profile/"+userData.customer_id} class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Edit Profile</h6>
                                    <p class="text-secondary mb-0 small">Review or Update your Edit Profile</p>
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/change_password" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Change Password</h6>
                                    <p class="text-secondary mb-0 small">Review or Update your Change Password</p>
                                </div>                                
                                <div class="col-2 pl-0 align-self-center text-right">
                                     <i class="material-icons text-secondary">chevron_right</i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-4 px-0c">
                        <h3>Other Settings</h3>
                    <ul class="list-group list-group-flush mb-4">
                        <li class="list-group-item">
                            <a href="" class="row">
                                <div class="col">
                                    <h6 class="text-dark mb-1">Language</h6>
                                </div>                                
                                <div class="col-6 pl-0 align-self-center text-right">
                                    <select class="form-select form-select-sm select_head_top">
                                        <option>English</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>


                

            </div>
            

</div>
        
    </div>
  )
}

export default Customer_settings
